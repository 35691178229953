import React, {useEffect, useCallback, useState} from 'react';
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import useHover from '../helpers/useHover'
import useMenuHover from '../helpers/useMenuHover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faInfoCircle, faMobile, faDonate, faEnvelope, faBars, faTimes, faAngleDown, faAngleRight, faAngleLeft, faStar  } from "@fortawesome/free-solid-svg-icons";
import {Link as _Link} from 'react-router-dom'
import useMobileCheck from '../helpers/useMobileCheck.js'
import logo from '../images/logos/logo.png'
import logoText from '../images/logos/SupportaTroop_Logo_BlackTxt.png'
import donate from '../images/donate.png'

const Link = x =>  <_Link to={x.to} style={{ textDecoration: 'none' }}>{x.children}</_Link>
const getDropDownOptions = expand =>
    expand == 'about' ? [{title:"About Us",link:'about'},{title:"Brandon's mission",link:'brandon'},{title:"Featured on",link:'features'}] :
    expand == 'mobileApp' ? [{title:"For the patriot", link:'mobile-app-donor'},{title:"For the deployed troop",link:'mobile-app-troop'}] :
    expand == 'waysToSupport' ? [{title:"Ways to support", link:'ways-to-support'},{title:"Soldier of the week", link:'soldier-of-the-week'}] : 
    []

const h = 65

export const Menu = ({v,sv,hh}) =>{
    const {width} = useWindowDimensions()
    const mobile = useMobileCheck() || width < 520
    const [open,setOpen] = useState(false)
    const [expand,se] = useState(null)
    const [ref,hover] = useMenuHover()
    useEffect(()=>{
        // console.log(hover)
        if(!hover){
            se(null)
        }
    },[hover])
if(mobile)
{
    return(
    <div style={{width:'100%',flexDirection:'column',position:'sticky',top:0,zIndex:101}}>
        <div style={{
            flexDirection:'row',
            width:'100%',
            height:h,
            overflow:'visible',
            display:'flex',
            alignItems:'center',
            justifyContent:'space-around',
            backgroundColor:'white',
            borderBottom:'1px solid #BDBDBD',
            zIndex:100
        }}>
            <div
                style={{
                    height:28,
                    width:60,
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    justifyContent:'center',
                    cursor:'pointer',
                    // border:'1px solid red'
                }}
                onClick={()=>{
                    setOpen(!open)
                    se(null)
                }}
            >
                <div
                    style={{
                        height:28,
                        width:28,
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center',
                        justifyContent:'center',
                        borderRadius:14,
                        marginRight:'10px'
                    }}
                >
                    <FontAwesomeIcon 
                        icon={open?faTimes:faBars} 
                        color="#004897"
                    />
                </div>
                <div
                    style={{fontSize:'12px',color:"#004897"}}
                >
                    {open?"close":"menu"}
                </div>
            </div>
            <Logo/>
            {/* <Link to='/donate' style={{textDecoration:'none',}}> */}
                <div
                    style={{
                        width:80,
                        height:30,
                        borderRadius:5,
                        color:'white',
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center',
                        justifyContent:'center',
                        fontSize:12,
                        // background: 'linear-gradient(0.25turn, #8d0d2f, #B8153C)',
                        // cursor:'pointer'
                    }}
                    // onClick={()=>sv('donate')}
                >
                    {/* DONATE */}
                </div>
            {/* </Link> */}
        </div>
        {open && !expand &&
            <div
                style={{
                    width:width,
                    backgroundColor:"#002147",
                    paddingBottom:'20px',
                    zIndex:20
                }}
            >
                <MobileLink name="Home" sv={sv} se={se} setOpen={setOpen} link="home"/>
                <MobileLinkDropper name="About Us" sv={sv} setOpen={setOpen} link="about" se={se} expand={expand}/>
                <MobileLinkDropper name="Mobile App" sv={sv} setOpen={setOpen} link="mobileApp" to="mobile-app" se={se} expand={expand}/>
                <MobileLinkDropper name="Ways To Support" sv={sv} setOpen={setOpen} link="waysToSupport" se={se} expand={expand}/>
                {/* <MobileLink name="Store" sv={sv} se={se} setOpen={setOpen} link="store"/> */}
                <MobileLink name="Contact Us" sv={sv} se={se} setOpen={setOpen} link="contact us" to="contact-us"/>
                {/* <MobileLink name="DONATE" sv={sv} se={se} setOpen={setOpen} link="donate"/> */}
            </div>
        }
        {open && expand &&
            <div
                style={{
                    width:width,
                    backgroundColor:"#002147",
                    paddingBottom:'20px',
                    zIndex:20
                }}
            >
                <div 
                    style={{
                        width:'100%',
                        textAlign:'center',
                        color:'white',
                        padding:15,
                        fontSize:'12px',
                        position:'relative'
                    }}
                >
                    {expand=="about"?"About":expand=="mobileApp"?"Mobile App":expand=='waysToSupport'?"Ways to Support":""}
                    <div 
                        style={{
                            position:'absolute',
                            left:0,
                            top:15,
                            color:'white',
                            padding:5,
                            fontSize:'12px',
                            cursor:'pointer'
                        }}
                        onClick={()=>se(null)}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} style={{marginRight:5}}/>
                        Go Back
                    </div>

                </div>
                {getDropDownOptions(expand).map(({title,link},i)=>
                    <MobileLink key={i} name={title} sv={sv} se={se} setOpen={setOpen} link={link} topBorder={i==0?true:false}/>                    
                )}
                <div
                    style={{width:'100%',paddingTop:110}}
                >
                    <Stars nine/>
                </div>
            </div>
        }
    </div>
)}
return(
    <div 
        ref={ref} 
        style={{
            position:'sticky',
            top:0,
            zIndex:20,
            width:'100%',
            height:h,
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between',
            backgroundColor:'white',
            borderBottom:'1px solid #BDBDBD'
        }}
    >
        <Logo/>
        <LinkBarItem margin linkTo='home' sv={sv} se={se} v={v}/>
        <Seperater height={mobile?30:h}/>
        <LinkBarDropper title="about" se={se} v={v} dropDown="about" expand={expand}/>
        <Seperater height={mobile?30:h}/>
        <LinkBarDropper title="mobile app" se={se} v={v} dropDown="mobileApp" expand={expand}/>
        <Seperater height={mobile?30:h}/>
        <LinkBarDropper title="ways to support" se={se} v={v} dropDown="waysToSupport" expand={expand}/>
        <Seperater height={mobile?30:h}/>
        {/* <LinkBarItem linkTo='store' sv={sv} se={se} v={v} last={true}/> */}
        {/* <DonateButton sv={sv}/> */}
        {expand && 
            <div
                style={{
                    position:'absolute',
                    top:h,
                    width:'100%',
                    backgroundColor:'#b61d24',
                    height:40,
                    flexDirection:'row',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-around'
                }}
            >
                <Stars left/>
                <div 
                    style={{
                        flex:2,
                        flexDirection:'row',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'space-around'
                    }}
                >
                    {getDropDownOptions(expand).map(({title,link},i)=>
                        <LinkBarItemDropper key={i} title={title} linkTo={link} sv={sv} se={se} v={v} white/>
                    )}
                </div>
                <Stars/>
            </div>
        }
    </div>
)}
const icons = {
    home: faHome,
    about : faInfoCircle,
    "mobile app": faMobile,
    donate: faDonate,
    "contact us": faEnvelope
}

const LinkBarItem = ({linkTo, sv, se, v, margin, title, white, last=false}) =>{
    const {width} = useWindowDimensions()
    const mobile = width < 450
    const [ref,hover] = useHover()
    useEffect(()=>{
        if(hover){
            se(null)
        }
    },[hover])

    return(
    <Link to={'/'+linkTo}>
        <div 
            ref={ref}
            style={{
                minWidth: 10,
                display:'flex',
                flexDirection:'row',
                justifyContent:'center',
                fontSize: mobile ? 11 : width < 575 ? 10 : width <700 ? 12 : 16,
                color:hover ? "#084b97" : 'black',
                cursor:'pointer',
                // fontWeight:'bold',
                marginLeft: margin ? 40 : 0,
                marginRight: last ? 40 : 0,
                // textDecoration : linkTo === v ? 'underline' : 'none',
                userSelect:'none',
                fontFamily:'Ariel',
                // letterSpacing:'1px',
                textAlign:'center'
            }}
            onClick={()=>{
                sv(linkTo)
                se(null)
            }}
        >
            {!mobile && ((title && title.toUpperCase()) || linkTo.toUpperCase())}
            {mobile && 
            <div
                style={{
                    height:28,
                    width:28,
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    borderRadius:14
                }}
            >
                {/* <FontAwesomeIcon 
                    icon={icons[linkTo]} 
                    size="2x"
                /> */}
            </div>
            }
        </div>
    </Link>
    )
}

const LinkBarItemDropper = ({linkTo, sv, se, v, margin, title, white}) =>{
    const {width} = useWindowDimensions()
    const mobile = width < 450
    const [ref,hover] = useHover()
    return(
    <Link to={'/'+linkTo}>
        <div 
            ref={ref}
            style={{
                minWidth: 10,
                display:'flex',
                flexDirection:'row',
                justifyContent:'center',
                fontSize: mobile ? 11 : width < 575 ? 10 : width <700 ? 12 : 16,
                color:hover ? "#e68a8e" : 'white',
                // color:linkTo === v ? "pink" : 'white',
                cursor:'pointer',
                // fontWeight:'bold',
                marginLeft: margin ? 20 : 0,
                // textDecoration : linkTo === v ? 'underline' : 'none',
                userSelect:'none',
                fontFamily:'Ariel',
                // letterSpacing:'1px',
                textAlign:'center'
            }}
            onClick={()=>{
                sv(linkTo)
                se(null)
            }}
        >
            {!mobile && ((title && title.toUpperCase()) || linkTo.toUpperCase())}
            {mobile && 
            <div
                style={{
                    height:28,
                    width:28,
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    borderRadius:14
                }}
            >
                <FontAwesomeIcon 
                    icon={icons[linkTo]} 
                    size="2x"
                />
            </div>
            }
        </div>
    </Link>
    )
}

const LinkBarDropper = ({dropDown, se, v, margin, title, expand}) =>{
    const {width} = useWindowDimensions()
    const mobile = width < 450
    const [ref,hover] = useHover()
    useEffect(()=>{
        if(hover){
            se(dropDown)
        }
    },[hover])
    return(
        <div 
            ref={ref}
            style={{
                minWidth: 10,
                display:'flex',
                flexDirection:'row',
                justifyContent:'center',
                fontSize: mobile ? 11 : width < 575 ? 10 : width <700 ? 12 : 16,
                color:expand === dropDown ? "#084b97" : 'black',
                cursor:'pointer',
                marginLeft: margin ? 20 : 0,
                userSelect:'none',
                fontFamily:'Ariel',
                position:'relative',
            }}
            onClick={()=>se(dropDown)}
        >
            {!mobile && 
                <div>{title.toUpperCase()}</div>
            }
            {!mobile && 
                <div
                    style={{
                        position:'absolute',
                        bottom:-20,
                        marginLeft:'auto',
                        marginRight:'auto'   
                    }}
                >
                    <FontAwesomeIcon icon={faAngleDown} />
                </div>
            }
            {mobile && 
                <div
                    style={{
                        height:28,
                        width:28,
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        borderRadius:14
                    }}
                >
                    <FontAwesomeIcon 
                        icon={icons[title]} 
                        size="2x"
                    />
                </div>
            }
        </div>
    )
}

const MobileLink = ({name,link,sv,setOpen,to,se,topBorder}) => 
    <Link to={'/' + (to?to:link)}>
        <div
            style={{
                borderBottomWidth:(name=="DONATE"||name=="Contact Us")?0:1,
                borderTopWidth:topBorder?1:0,
                borderColor:'#697e96',
                borderStyle:'solid',
                borderRightWidth:0,
                borderLeftWidth:0,
                marginLeft:10,
                marginRight:10,
                color:'white',
                height:40,
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:name=="DONATE"?'center':undefined,
                background:name=="DONATE"? 'linear-gradient(0.25turn, #8d0d2f, #B8153C)':undefined,
                borderRadius:name=="DONATE"?5:undefined,
                fontSize:'12px',
                cursor:'pointer',
            }}
            onClick={()=>{
                sv(link)
                se(null)
                setOpen(false)
            }}
        >
            <div>
                {name}
            </div>
        </div>
    </Link>


const MobileLinkDropper = ({name,link,sv,setOpen,to,se,expand}) => 
    <div
        style={{
            borderBottomWidth:1,
            borderColor:'#697e96',
            borderStyle:'solid',
            borderTopWidth:0,
            borderRightWidth:0,
            borderLeftWidth:0,
            marginLeft:10,
            marginRight:10,
            color:'white',
            height:40,
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            fontSize:'12px',
            cursor:'pointer',
        }}
        onClick={()=>{
            se(link)
        }}
    >
        <div>
            {name}
        </div>
        <FontAwesomeIcon
            style={{position:'absolute',right:30}}
            icon={faAngleRight} 
        />
    </div>

const Logo = () =>{
    const {width} = useWindowDimensions()
    const mobile = useMobileCheck() || width < 520
    return(
        <div style={{
            width:mobile?100:120,
            height:mobile?30:h,
            flexDirection:'row',
            display:'flex',
            alignItems:'center',
            marginLeft:10
        }}>
            <img 
                style={{height:mobile?30:h-10,objectFit:'contain'}}
                src={logo} 
                alt="logo" 
            />
            <img 
                style={{height:mobile?30:h-10,objectFit:'contain'}}
                src={logoText} 
                alt="logo" 
            />
        </div>
)}

const Seperater = ({height}) =>
    <div 
        style={{
            height:height*0.35,
            width:1,
            borderLeft:'1px solid black'
        }}
    />

const DonateButton = ({sv}) =>
    // <Link to='/donate' style={{textDecoration:'none'}}>
        <div
            style={{
                // cursor:'pointer',
                width:80,
                height:30,
                marginRight:20,
                alignItems:'center',
                justifyContent:'center',
                display:'flex'
            }}
        >
            {/* <img
                src={donate}
                style={{width:'100%'}}
                alt="donate button"
            /> */}
        </div>
    // </Link>

const DonateButtonOld = ({sv}) =>
    <Link to='/donate' style={{textDecoration:'none'}}>
        <div
            style={{
                fontSize: 12,
                cursor:'pointer',
                fontWeight:'bold',
                userSelect:'none',
                fontFamily:'Arapey',
                letterSpacing:'1px',
                width:80,
                height:30,
                background: 'linear-gradient(0.25turn, #8d0d2f, #B8153C)',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                color:'white',
                borderRadius:3,
                marginRight:20
            }}
            onClick={()=>sv('donate')}
        >
            DONATE
        </div>
    </Link>

const Stars = ({left,nine}) => 
    <div
        style={{
            flex:1,
            flexDirection:'row',
            display:'flex',
            alignItems:'center',
            justifyContent:'space-around',
            paddingLeft:nine?40:0,
            paddingRight:nine?40:0
        }}
    >
        {
            Array(nine?9:7).fill().map((x,i)=>
                <FontAwesomeIcon key={i} icon={faStar} style={{opacity:'0.5'}} size="xs" color="white"/>
            )
        }
    </div>