import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from "@fortawesome/free-solid-svg-icons";
import useMobileCheck from '../helpers/useMobileCheck.js'
import useWindowDimensions from '../helpers/useWindowDimensions.js'

import logo1 from '../images/logos/1.jpg'
import logo2 from '../images/logos/2.png'
import logo3 from '../images/logos/3.jpg'
import logo4 from '../images/logos/4.jpg'

const features = [
    {
        // i heart radio
        image: logo3,
        text: 'READ/LISTEN',
        link: 'https://wflanews.iheart.com/content/2021-02-22-local-veteran-creates-support-a-troop-app/ '
    },
    {
        // pbs
        image: logo4,
        text: 'WATCH VIDEO',
        link: 'https://www.youtube.com/watch?v=UdSVqVKm-Ho&t=6s '
    },
    {
        // hernando sun
        image: logo1,
        text: 'READ',
        link: 'https://www.hernandosun.com/article/veteran-giving-back-those-who-give-all '
    },
    {
        //heros first
        image: logo2,
        text: 'WATCH VIDEO',
        link: 'https://www.youtube.com/watch?v=BaIedml4gRc&t=323s'
    },
]

export const Features = () => {
    const {width} = useWindowDimensions()
    const mobile = useMobileCheck() || width < 520
    return(

    <div style={{width:'100%',display:'flex',flexDirection:'column'}}>

        {/* BANNER */}

        <div 
            style={{
                width:'100%',
                display:'flex',
                flexDirection:'row',
                backgroundColor:"#ededed",
                alignItems:'center',
                paddingTop:16,
                paddingBottom:16,
                marginTop:15
            }}
        >
            <Stars/>
            <div 
                style={{
                    fontSize: mobile ? 20 : 30,
                    textAlign:'center',
                    color:'#18274e',
                    fontFamily:'Roboto'
                }}
            >
                FEATURED ON
            </div>
            <Stars/>
        </div>

        {/* FEATURES */}

        <div
            style={{
                display:'flex',
                flexDirection:'column',
                marginLeft:'15%',
                paddingBottom:100
            }}
        >
            {features.map(({image,text,link},i)=>
                <div
                    key={i}
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        margin:30,
                        alignItems:'center'
                    }}
                >
                    <img
                        style={{width:'20%',objectFit:'contain'}}
                        src={image}
                        alt="corporate logo"
                    />
                    <div
                        style={{
                            fontSize:16,
                            textDecoration:'underline',
                            color:"#18274e",
                            marginLeft:40,
                            fontFamily:'Roboto',
                            cursor:'pointer'
                        }}
                        onClick={()=>window.open(link, "_blank")}
                    >
                        {text}
                    </div>
                </div>
            )}
        </div>
    </div>
)}

const Stars = () => 
    <div
        style={{
            flex:1,
            flexDirection:'row',
            display:'flex',
            alignItems:'center',
            justifyContent:'space-around',
            paddingLeft:20,
            paddingRight:20
        }}
    >
        {
            Array(6).fill().map((x,i)=>
                <FontAwesomeIcon key={i} icon={faStar} style={{opacity:'0.5'}} color="grey"/>
            )
        }
    </div>