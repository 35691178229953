import T1 from "../images/links/troops/1.png"
import T2 from "../images/links/troops/2.jpg"
import T3 from "../images/links/troops/3.jpg"
import T4 from "../images/links/troops/4.jpg"
import T5 from "../images/links/troops/5.jpg"
import T6 from "../images/links/troops/6.jpg"
import T7 from "../images/links/troops/7.jpg"
import T8 from "../images/links/troops/8.jpg"
import T9 from "../images/links/troops/9.jpg"
import T10 from "../images/links/troops/10.jpg"
import T11 from "../images/links/troops/11.jpg"
import T12 from "../images/links/troops/12.jpg"
import T13 from "../images/links/troops/13.jpg"
import T14 from "../images/links/troops/14.jpg"
import T15 from "../images/links/troops/15.jpg"

export const testimonials = [
    {
        title:"",
        message: "Myself, and a few of my fellow Soldiers who are deployed with me, have received items from our wish list through Support a Troop. On our end, it’s super easy to sign up and we love being able to select the items we need. We receive the items fairly quick too! Thank you so much for making our deployment a little more comfortable by donating these items to us and all of the other deployed service members. We truly appreciate your organization and all of the donors who support us. : ) Thank you for making it so easy and simple.",
        name: "Julia aka princessjuju3,",
        date: "01/22/2021`",
        photo: T1,
    },
    {
        title:"deployed member",
        message:"I can not express my gratitude for this app enough myself. A few of my squadron members and I have utilized this app and it has been nothing short of a good experience in helping make our time away go by a little easier giving us the opportunity to have the little things we miss or need most. I 10/10 recommend and will join the other side of this app when I get out. What a huge difference it truly makes- to the creators- and supports we can’t Thank-you enough.",
        name:"katiekeller91!,",
        date:"03/05/2022",
        photo: T2
    },
    {
        title:"10/10",
        message:"There is nothing that makes you feel more at home than receiving mail, from people who support what you are doing! Words can’t express the gratitude of how amazing the people are on this app! Can’t thank Support our Troops enough for this opportunity.",
        name:"Ashley T3,",
        date:"03/06/2022",
        photo: T3
    },
    {
        title:"Thank you",
        message:"Sir, thank you so much for the care package. People such as yourself help make deployments a little less rough by your acts of kindness. Thank you again!",
        name:"Deployed Troop,",
        date:"04/21/22",
        photo: T4
    },
    {
        title:"Military Working K-9",
        message:"Thank you so much for the gifts! Me and rocky appreciate your generosity!",
        name:"Rocky,",
        date:"04/23/2022",
        photo: T5
    },
    {
        title:"Thank you",
        message:"Thank you SupportATroop for the carepackage! This absolutely blew me away and put a smile on our faces when I told the rest of the PLT about your app and mission. Thank you very much for the package and snacks!",
        name:"Deployed Troop,",
        date:"12/28/21",
        photo: T6
    },
    {
        title:"Thank you",
        message:"Just received part of the items you have donated. I want to say I am very thankful for the support and none of it will be going to waste. The energy drinks were spot on, these are my favorite brand and flavor! 👍🏼",
        name:"Deployed Troop,",
        date:"04/25/2022",
        photo: T7
    },
    {
        title:"Thank you",
        message:"Can’t wait to read, and thank you so much!!!",
        name:"Deployed Troop,",
        date:"02/13/2022",
        photo: T8
    },
    {
        title:"Very Rewarding!",
        message:"It is so nice to receive a personal thank you for your support. I have supported 3 so far and it's so rewarding!",
        name:"Joanne Obrien,",
        date:"03/22/2022",
        photo: T9
    },
    {
        title:"Awesome App who cares for soldiers!",
        message:"I am glad that I can help soldiers get items that they choose and need. It is easy to sign up and I am notified when my package is sent and received. Be a hero to another hero by sending a care package!",
        name:"L.B. JAMES,",
        date:"10/19/2021",
        photo: T10
    },
    {
        title:"A wonderful way to show you patriotism",
        message:"This app is the best way I found to help the military. I just downloaded it, it was so easy to use. I already sent out 2 care packages for under 20 dollars. I cant wait to receive a photo back from them! So wonderful!",
        name:"AlbertJWesker,",
        date:"02/03/2021",
        photo: T11
    },
    {
        title:"",
        message:"`Thank you for the much needed support and high boost of morale. Know this much, that you along with the rest of the supporters keep myself and the rest of my soldiers in good spirits through rough times.",
        name:"Deployed Troop,",
        date:"01/28/2021",
        photo: T12
    },
    {
        title:"Thank You",
        message:"Thank you so much for the donation, receiving mall is one of the biggest blessing we can have! I can not tell you how much it is appreciated and how grateful I am! Thank you again and I hope you have a blessed day!",
        name:"Deployed Troop,",
        date:"06/14/2021",
        photo: T13
    },
    {
        title:"Thank you HEROS",
        message:"This is such a wonderful app to send needed and wanted items all across the world to our troops! Wish I was rich! I would give to each and every one of you! Stay strong and thank you from the bottom of my heart for your service and sacrifices ❤️🇺🇸",
        name:"Batman cave230,",
        date:"01/31/2021",
        photo: T14
    },
    {
        title:"Appreciate the confirmation status of delivery & receipt.",
        message:"I especially appreciate the message function where the Service member can send me a note indicating they’ve received the items I selected for them & can send me a photo. It has reassured my confidence in this program that my donation has actually been received - thank you for that functionality! And THANK YOU so much for creating this platform where we can give, we can choose from a recipients wish list & that the Service member has the ability to confirm it’s been received.💗💗💗",
        name:"TinaBell,",
        date:"02/22/2021",
        photo: T15
    }
]