import React from 'react';
import {TermsAndConditionsText} from '../assets/termsAndConditionsText.js'

export const TermsAndConditions = () =>
<div style={{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
}}>
    <div style={{
        fontSize:28,
        textAlign:'center',
        margin:24,
        fontWeight:'bold'
    }}>
Terms And Conditions    </div>
    <div style={{
        margin:45,
        marginTop:10,
        whiteSpace:'pre-wrap',
        marginTop:0,
        marginBottom:0
    }}>
        Thank you for visiting Support A Troop website. Please carefully review the following terms and conditions. These Terms and Condition are a legally binding contract between you and the Support A Troop, pertaining to the access and use of any and all information, media, content printed materials, “online” or other electronical documentation, products, and services available from http://www.supportatroop.com (and each sub domain associated with this URL).
    </div>
    <div style={{
        margin:45,
        marginTop:10,
        whiteSpace:'pre-wrap',
        color:'#0D26B4',
        textAlign:'center',
        margin:0
    }}>
        -Introduction-
    </div>
    <div style={{
        margin:45,
        marginTop:10,
        whiteSpace:'pre-wrap',
    }}>
        {makeBlue(TermsAndConditionsText)}
    </div>
</div>

const makeBlue = t => {
    var t1 = t.split('\n')
    // console.log(t1)
    var lines = []
    var line = ''
    for(let i=0;i<t1.length;i++)
    {
        if( (t1[i].search('SECTION') == -1) || (t1[i].search('-') == -1) ){
            line += t1[i] + '\n'
        } else {
            lines.push(line)
            lines.push(t1[i])
            line=''
        }
        if(i==t1.length-1){
            lines.push(line)
        }
    }
    let t2 = lines.map(x=>{
        if((x.search('SECTION') == -1) || (x.search('-') == -1)){
            return <div>{x}</div>
        } else {
            return <div style={{color:'#0D26B4',textAlign:'center'}}>{x}</div>
        }
    })
    // console.log(lines)
    return t2
}