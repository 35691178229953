import React from 'react';
import { colors } from '../assets/colors.js';
import thanks from '../images/thanks.jpg'
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import {Link as _Link} from 'react-router-dom'
const Link = x =>  <_Link to={x.to} style={{ textDecoration: 'none',color:colors.medBlue, ...x.style }}>{x.children}</_Link>

const header = {
    fontSize:'25px',fontWeight:'bold',color:"#233c7a",
    margin:'16px',marginBottom:0,marginLeft:"15%"
}
const info = {
    fontSize:'14px',color:"#233c7a",
    margin:'16px',marginTop:0,marginLeft:"16%"
}

const date = new Date().toGMTString()
const uid = (Math.random() * 1000000).toFixed()

export const DonationThanks = ({sv,showThanks,setShowThanks}) => {
    const {width} = useWindowDimensions()
    const {firstName,lastName,email,amount,customAmount,monthly} = showThanks
        return(
                <div
                    style={{
                        width:'100%',
                        backgroundColor:'white',
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center'
                    }}
                >
                    <div style={{
                        fontSize:'30px',
                        fontFamily:"Roboto",
                        margin:20,
                        color:"#233c7a"
                    }}>
                        Thank You for your donation!
                    </div>
                    <div
                        style={{
                            width:'100%',
                            minHeight:100,
                            backgroundColor:"#ededed",
                            paddingTop:20,
                            marginBottom:500
                        }}
                    >
                        <div style={{display:'flex', flexWrap:'wrap', flexDirection:'row',width:'100%'}}>
                            <div style={{flexDirection:'column',flex:1,padding:20}}>
                                <div style={{
                                    ...header,textAlign:'center',marginLeft:'15%',
                                    marginRight:'15%',fontSize:30,marginBottom:20}}
                                >
                                    Our service members thank you
                                </div>
                                <div style={{...info,textAlign:'center',margin:0,marginLeft:0,marginBottom:40}}>
                                    The troops see how much you care, your generosity is greatly appreciated. Your contributions strengthen the U.S. Armed Forced and the Support A Troop Donations organization. Thank you!
                                </div>
                                <div style={header}>DATE</div>
                                <div style={info}>{date}</div>
                                {/* <div style={header}>REFERENCE #</div>
                                <div style={info}>{"TRI-cb-"+uid}</div> */}
                                <div style={header}>CONTRIBUTION</div>
                                <div style={info}>{"$" + (amount || parseFloat(customAmount)).toFixed(2)}</div>
                                <div style={header}>PERSONAL INFORMATION</div>
                                <div style={{...info,marginBottom:0}}>{`${firstName} ${lastName}`}</div>
                                <div style={info}>{email}</div>
                            </div>
                            <div style={{flexDirection:'column',display:'flex',flex:1,padding:20,justifyContent:'center',alignItems:'center'}}>
                                <img 
                                    src={thanks} 
                                    style={{width:'100%',objectFit:'contain'}}
                                    alt="soldier with goods"
                                />
                                <div
                                    style={{
                                        marginTop:20,
                                        borderRadius:4,
                                        backgroundColor:"#233c7a",
                                        color:"white",
                                        fontSize:14,
                                        width:"60%",
                                        padding:12,
                                        textAlign:'center',
                                        cursor:'pointer'
                                    }}
                                    onClick={()=>{
                                        sv('ways-to-support')
                                        setShowThanks({show:false})
                                    }}
                                >
                                    <Link to="ways-to-support" style={{color:'white',textAlign:'center'}}>
                                            CLICK HERE FOR MORE WAYS TO SUPPORT
                                    </Link>
                                </div>

                            </div>
                        </div>
                        {/* {   
                            monthly &&
                            <div style={{
                                width:'100%',
                                fontSize:13,
                                color:colors.medGray,
                                margin:10
                            }}>
                                {`Your recurring monthly contribution of $${amount||customAmount} will begin next month, to cancel this recurring payment anytime email donations@isupportdeployedtroops.com`}
                            </div>
                        } */}
                    </div>
                    {/* <div style={{
                        width:'80%',
                        fontSize:13,
                        color:colors.medGray,
                        margin:10
                    }}>
                        Thank you for honoring the services and sacrifices that our brave men and women in uniform make everyday - serving the country miles from home is dangerous and hard on them and their families, your contributions make all the difference
                    </div> */}
                </div>
        )
    }
