import React, {useEffect} from 'react';
import {comments} from '../assets/comments_new.js'
import {animated} from 'react-spring'
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import useMobileCheck from '../helpers/useMobileCheck.js'
import useHover from '../helpers/useHover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faStar} from "@fortawesome/free-solid-svg-icons";

export const CommentPage = ({op,commentNumbers,all=false}) =>{

const {width} = useWindowDimensions()
const mobile = useMobileCheck() || width < 520

return(
<animated.div style={{
    width:'100%',
    position:'absolute',
    top:0,
    left:0,
    opacity:op,
    display:'flex',
    flexDirection:'column'
}}>
    <div style={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        padding:10,
        paddingBottom:0
    }}>
        <Comment text={comments[commentNumbers[0]]}/>
        <Comment text={comments[commentNumbers[1]]}/>
        {!mobile && <Comment text={comments[commentNumbers[2]]}/>}
    </div>
    <div style={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        padding:10,
        paddingTop:0,
        paddingBottom:50
    }}>
        {all && <Comment text={comments[commentNumbers[3]]}/>}
        {all && <Comment text={comments[commentNumbers[4]]}/>}
        {all && !mobile && <Comment text={comments[commentNumbers[5]]}/>}
    </div>
    
</animated.div>

)}

const Comment = ({text}) => {
    const [ref,hover] = useHover()
    const {width} = useWindowDimensions()
    const mobile = useMobileCheck() || width < 520
    return(
        <div 
            style={{
                width:mobile?'48%':230,
                height:230,
                display:'flex',
                flexDirection:'column',
                position:'relative',
                margin:5,
                marginTop:0,
                backgroundColor:'white',
            }}
            ref={ref}
        >
            <div
                style={
                    (hover && !mobile && text.s)?{
                        position:'absolute',
                        top:0,
                        left:0,
                        width:mobile?'100%':230,
                        boxShadow:hover?"0px 0px 30px grey":"0px 0px 30px grey",
                        zIndex:hover?2:1,
                        backgroundColor:'white',
                        cursor:'default'
                    }:
                    {
                        position:'absolute',
                        top:0,
                        left:0,
                        width:mobile?'100%':230,
                        height:230,
                        boxShadow:hover?"0px 0px 30px grey":"0px 0px 10px grey",
                        zIndex:hover?2:1,
                        backgroundColor:'white',
                        cursor:'default'
                    }
                }
            >
                {/* <FontAwesomeIcon 
                    icon={faQuoteLeft} 
                    color="#18274e"
                    size="2x"
                    style={{margin:5}}
                /> */}
                <div
                    style={{
                        fontFamily:"Ariel",
                        fontSize:100,
                        position:'absolute',
                        top:-10,
                        left:0
                    }}
                >
                    “
                </div>
                <div 
                    style={{
                        marginTop:45,
                        fontSize:mobile?10:13,
                        lineHeight:1.5,
                        backgroundColor:'white',
                        padding:10,
                        paddingTop:5,
                        marginBottom:0,
                        color:"#18274e"
                    }}
                >
                    {(hover && !mobile) ? text.c : (text.s || text.c)}
                </div>
                <div 
                    style={{
                        // marginTop:55,
                        paddingLeft:13,
                        fontSize:13,
                        marginBottom:40,
                        color:"#18274e"
                    }}
                >
                    {(!text.s || (hover && !mobile)) && text.t}
                </div>
                {!mobile && <Stars/>}
            </div>
        </div>
)}

const Stars = () => {
    const {width} = useWindowDimensions()
    const mobile = useMobileCheck() || width < 520
    return(
        <div
            style={{
                position:'absolute',
                bottom:10,
                left:0,
                width:160,
                flex:1,
                flexDirection:'row',
                display:'flex',
                alignItems:'center',
                justifyContent:'space-around',
                paddingLeft:30,
                paddingRight:30
            }}
        >
            {
                Array(5).fill().map((x,i)=>
                    <FontAwesomeIcon key={i} icon={faStar} style={{opacity:'0.5'}} color="gold"/>
                )
            }
        </div>
)}