import React, {useState, useEffect} from 'react';
import image from '../images/SoldierWeek-ComingSoon_2.jpg'

export const CommingSoonSoldier = () => 
    <div style={{width:'100%',display:'flex',flexDirection:'column'}}>
        <img
            src={image}
            style={{width:'100%',objectFit:'contain'}}
            alt="coming soon : solder of the week"
        />
    </div>