import React,{useState,useEffect} from 'react';
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import header from '../images/sponsorPage/header.png'
import header_mobile from '../images/sponsorPage/header_mobile.jpg'

export const Sponsors = () => {
    const [sponsors,setSponsors] = useState({})
    const {width} = useWindowDimensions()
    const mobile = width < 450
    useEffect(()=>{
        async function fetchData(){
            let res =  await fetch('https://us-central1-care-c7a53.cloudfunctions.net/fetchSponsors',
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method:"POST",
            })
            let json = await res.json()
            console.log(json)
            json!=null && setSponsors(json)
        }
        fetchData()
    },[])
    const companies = sponsors.companies ? Object.values(sponsors.companies) : []
    const influencers = sponsors.influencers ? Object.values(sponsors.influencers) : []
    const volunteers = sponsors.volunteers ? Object.values(sponsors.volunteers) : []
    return(
        <div style={{display:'flex',width:'100%',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <img src={mobile?header_mobile:header} width='100%'/>
            {companies.length > 0 && <h1 style={{marginRight:'auto',marginLeft:'2.5%'}}>Companies</h1>}
            {
                companies.map(x=>scrollImage(x))
            }
            {influencers.length > 0 && <h1 style={{marginRight:'auto',marginLeft:'2.5%'}}>Social Media Influencers</h1>}
            {
                influencers.map(x=>scrollImage(x))
            }
            {volunteers.length > 0 && <h1 style={{marginRight:'auto',marginLeft:'2.5%'}}>Volunteers</h1>}
            {
                volunteers.map(x=>scrollImage(x))
                // volunteers.map(x=><img onClick={x=>console.log('click')} src={x} width='95%' style={{marginBottom:10}}/>)
            }
        </div>
    )
}

const scrollImage = i => 
    <img 
        onClick={()=>i.link && i.link!='' && window.open(i.link, "_blank")} 
        src={i.image} 
        width='95%' 
        style={{marginBottom:10,cursor:i.link && i.link!='' ? 'pointer' : 'default'}}
    />