import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBag  } from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import {Store} from '../components/supportATroop.js'
import {Link} from 'react-router-dom'
import {useParams} from "react-router-dom";

const noItem = {
    item: {
        name : "No Item Selected",
        desc : ""
    }
}

export const ViewItem = ({useCart,items}) => {
    const params = useParams()
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    const [quantity,setQuantity] = useState(1)
    const [cart,setCart,selected,setSelected] = useCart
    const {item,num,id} = selected || noItem
    const {width} = useWindowDimensions()
    const mobile = width < 420
    useEffect(()=>{
        // if there is no product selected, show a new product
        if(selected == null){
            setSelected(items[0])
        }
        // after 'add to cart' it will move a new product automatically
        if(cart[id] != undefined){
            setSelected(items[0])
        }
        if((params.id != undefined) && (items.length > 0)){
            const paramItem = items.filter(x=>x.id == params.id)[0]
            paramItem && setSelected(paramItem)
        }
    },[items])
    if(selected == null){
        return null
    }
    return(
    <div style={{width:'100%',display:'flex',flexDirection:'column'}}>
        <div style={{width:'100%',display:'relative',minHeight:80}}>
            <div style={{fontSize:22,fontWeight:'bold',margin:20,marginBottom:2,marginRight:mobile?0:190,marginTop:mobile?100:20}}>
                {item.name + " | " + item.desc}
            </div>
            <Link to="/cart">
                <div style={{position:'absolute',top:30,right:50,fontSize:40,cursor:'pointer'}}>
                    <FontAwesomeIcon 
                        icon={faShoppingBag}
                        color="gray"
                    />
                </div>
                <div
                    style={{position:'absolute',top:26,right:40,borderRadius:13,height:25,
                            width:25,backgroundColor:"#017dfc",...center}}
                >
                    {Object.keys(cart).length}
                </div>
            </Link>
        </div>
        <div style={{width:'100%',display:'flex',flexDirection:mobile?'column':'row'}}>
            <div style={{flex:1,...center,height:mobile?width:width/2}}>
                <img
                    style={{objectFit:'contain',width:mobile?width-10:width/2 - 50,height:mobile?width-10:width/2 - 50}}
                    src={item.imageURL}      
                />
            </div>
            <div style={{flex:1,height:mobile?width-10:width/2,...center}}>
                <div style={{flex:1,margin:20,border:'1px solid #f3f3f3'}}>
                    <div style={{flex:1,backgroundColor:'#f3f3f3',...center}}>
                        <div style={{margin:10,marginBottom:20,fontWeight:'bold'}}>
                            {num + " Troops are requesting this item."}
                        </div>
                    </div>
                    <div style={{margin:12,marginTop:30}}>
                        {"$" + item.price}
                    </div>
                    <div style={{margin:12,flex:1,display:'flex',flexDirection:'row'}}>
                        <div>
                            {"Quantity :"} 
                        </div>
                        <select
                            value={quantity}
                            onChange={e=>setQuantity(e.target.value)}
                        >
                            {[...Array(num).keys()].map(x=>
                                <option 
                                    key={x}
                                    value={x+1}
                                >
                                    {x+1}
                                </option>
                            )}
                        </select>
                    </div>
                    <div 
                        style={{flex:1,margin:20,height:30,backgroundColor:blue, ...center, cursor:'pointer'}}
                        onClick={()=>{
                            setCart({...cart,[id]:{item,id,quantity}})
                            setQuantity(1)
                            window.scrollTo(0,0)
                        }}
                    >
                        <div style={{color:'white'}}>
                            ADD TO CART
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        <Store showTop={false} items={items} useCart={useCart}/>
    </div>
    )
}

const center = {display:'flex',alignItems:'center',justifyContent:'center'}
const blue = "#21376d"