export const getStripeToken = async ({number,exp,cvc,donation=true,test=false}) =>{
    const card = {
        'card[number]': number.replace(/ /g, ''),
        'card[exp_month]': parseInt(exp.split('/')[0]),
        'card[exp_year]': parseInt(exp.split('/')[1]),
        'card[cvc]': cvc
    };
    let authBearer = 
    test ?
        //test mode
        donation ?
        'Bearer pk_test_ZYHWqn4vf6k6owcQEOBbOs1B00cQ2WgDjU' :
        'Bearer pk_test_v1jhl3wZMWtEDx3P34xOisUd00pZmlkBiX'
        //live mode
        :
        donation ?
        'Bearer pk_live_WMLpzvoxV88l2sZbMb6WUQ8F00IvhT2uuH' :
        "Bearer pk_live_5TAEqxFJCoDFTuchluYAnQCP00xuuJwb6U"

// live mode purchase
    // let authBearer = 'Bearer pk_live_5TAEqxFJCoDFTuchluYAnQCP00xuuJwb6U'
// test mode purchase
    // let authBearer = 'Bearer pk_test_v1jhl3wZMWtEDx3P34xOisUd00pZmlkBiX'
//test mode donation
    // let authBearer = 'Bearer pk_test_ZYHWqn4vf6k6owcQEOBbOs1B00cQ2WgDjU'
// live mode donation    
    // let authBearer = 'Bearer pk_live_WMLpzvoxV88l2sZbMb6WUQ8F00IvhT2uuH'

    let res = await fetch('https://api.stripe.com/v1/tokens', {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: authBearer
        },
        method: 'post',
        body: Object.keys(card).map(key => key + '=' + card[key]).join('&')
    })
    let resJSON = await res.json()
    return resJSON
}

export const onToken = async (data) => {
    const res =  await fetch('https://us-central1-care-c7a53.cloudfunctions.net/webDonation',
    {
        headers: {
            'Content-Type': 'application/json'
          },
        method:"POST",
        body:JSON.stringify(data)
    })
    const resJSON = await res.json()
    return(resJSON)
}

export const onTokenPurchase = async (data) => {
    const res =  await fetch('https://us-central1-care-c7a53.cloudfunctions.net/webPurchase',
    {
        headers: {
            'Content-Type': 'application/json'
          },
        method:"POST",
        body:JSON.stringify(data)
    })
    const resJSON = await res.json()
    return(resJSON)
}

export const onTokenAppDonation = async (data) => {
    console.log(data)
    const res =  await fetch('https://us-central1-care-c7a53.cloudfunctions.net/subscribeApp',
    {
        headers: {
            'Content-Type': 'application/json'
          },
        method:"POST",
        body:JSON.stringify(data)
    })
    const resJSON = await res.json()
    return(resJSON)
}

