import React, {useCallback, useEffect, useState} from 'react';
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import image from '../images/linksImage.jpg'
import logo from '../images/logos/logo.png'
import {Link} from 'react-router-dom'
import AppStore from '../images/logos/AppStore.png'
import GooglePlay from '../images/logos/GooglePlay.png'
import Troop from '../images/links/Soldier.png'
import Background from '../images/links/Background2.jpeg'
import Stars from '../images/links/stars.png'
import Icon from '../images/links/icon.png'
import Apple from '../images/links/apple.png'
import Google from '../images/links/google.png'
import ArrowIcon from '../images/links/arrow.png'

import {useSpring, animated} from 'react-spring'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import { testimonials } from '../assets/testimonials.js';

const BLUE = "#18274e"

export const Links = ({sv}) => {
    const {width, height} = useWindowDimensions()
    const mobile = (width < 485) || (height < 485)
    useEffect(()=>{
        if(
            mobile &&
            window.screen &&
            window.screen.orientation &&
            window.screen.orientation.lock
        ){
            window.screen.orientation.lock("portrait")
        }
    },[])
    if(mobile) 
    return(
        <div style={{width, height, display:'flex',flexDirection:'column',alignItems:"center", overflow:'hidden', height:"100%", backgroundColor:'black'}}>
            <img
                src={Icon}
                style={{width:'30%', marginTop:10, zIndex:2}}
                alt="image"
            />
            <div style={{fontSize:13,textAlign:'center',marginTop:15, color:'white', zIndex:2}}>
                <b>Support A Troop</b> is a tool for your heart ❤️
            </div>
            <div style={{fontSize:13,textAlign:'center',marginTop:15, color:'white', zIndex:2}}>
                We make it easy to send gifts to a 
            </div>
            <div style={{fontSize:13,textAlign:'center',marginTop:5, color:'white', zIndex:2}}>
                deployed service member🇺🇸
            </div>
            <img
                src={Apple}
                style={{ width:width * 0.5, marginTop:15, cursor:"pointer", backgroundColor:'black', zIndex:2 }}
                alt="App Store"
                onClick={()=>window.open("https://apps.apple.com/ca/app/isupport-care-package-builder/id1495734737", "_blank")}
                />
            <img
                src={Google}
                style={{ width: width * 0.5, marginTop:12, cursor:"pointer", zIndex:2 }}
                alt="Google Play Store"
                onClick={()=>window.open("http://play.google.com/store/apps/details?id=com.iSupportDeployedTroops.iSupportCarePackageBuilder", "_blank")}
            />
            <Testimonials/>

            {/* FLAG BACKGROUND */}
            <div style={{position:'absolute', right:0, left:0, top:0, bottom:0, overflow:"hidden"}}>
                <img
                    src={Background}
                    style={{backgroundColor:"#18274e", objectFit: "cover"}}
                    alt="Image of happy troops"
                    width={width}
                    height={height}
                />
            </div>
            <div style={{position: 'absolute', bottom:5, left:0, right:0, width:"100%", textAlign:"center", zIndex:2, color:"lightgrey", fontSize:8}}>
                The appearance of DOD information does not imply or constitute DOD endoresment.
            </div>
        </div>
    )
    return(
        <div style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
            <div
                style={{
                    width:100,
                    height:100,
                    borderRadius:10,
                    boxShadow:'0px 0px 16px 0px gray',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    marginTop:40
                }}
            >
                <img
                    src={logo}
                    style={{width:95}}
                    alt="image"
                />
            </div>
            <div style={{fontSize:18,textAlign:'center',marginTop:15}}>
                Support A Troop
            </div>
            <div style={{fontSize:13,textAlign:'center',marginTop:5}}>
                Through a care package, you can send a piece of
            </div>
            <div style={{fontSize:13,textAlign:'center'}}>
                home their way.
            </div>
            <Link to='home' style={{textDecoration:'none',color:'grey'}}>
                <div
                    style={{
                        width:110,
                        height:30,
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        justifyContent:'center',
                        border:'2px solid grey',
                        borderRadius:15,
                        fontSize:13,
                        textAlign:'center',
                        marginTop:15,
                        cursor:'pointer'
                    }}
                    onClick={()=>sv('home')}
                >
                    Learn more
                </div>
            </Link>
            <img
                src={AppStore}
                style={{width:180,marginTop:12,cursor:"pointer"}}
                alt="App Store"
                onClick={()=>window.open("https://apps.apple.com/ca/app/isupport-care-package-builder/id1495734737", "_blank")}
                />
            <img
                src={GooglePlay}
                style={{width:180,marginTop:12,cursor:"pointer"}}
                alt="Google Play Store"
                onClick={()=>window.open("http://play.google.com/store/apps/details?id=com.iSupportDeployedTroops.iSupportCarePackageBuilder", "_blank")}
            />
            <img
                src={image}
                style={{width:'100%'}}
                alt="Image of happy troops"
            />
        </div>
    )
}

const TESTIMONIAL_MARGIN = 12

const Testimonials = () => {
    const {width, height} = useWindowDimensions()
    const [props, set] = useSpring(()=>({from: {offset:width*0.05}, config: {friction: 30}}))
    const [position, setPosition] = useState(0)
    return(
        <div style={{width: width, display:'flex', zIndex:2, position:'relative'}}>
            <animated.div style={{marginLeft:props.offset, display:'flex', marginTop:40, }}>
                { testimonials.map((data,index)=>
                    <Testimonial data={data} index={index} position={position}/>
                )}
            </animated.div>
            <Arrow 
                dir="left" 
                active = {position > 0}
                style={{position:'absolute', left:0, top: width*0.25 + 40, opacity:0.8}}
                onClick={()=>{
                    if(position > 0)
                    {
                        let newPosition = position - 1
                        set({offset:  (width * 0.05) - (newPosition * TESTIMONIAL_MARGIN) - (newPosition * width * 0.9)})
                        setPosition(newPosition)
                    }
                }}    
                />
            <Arrow 
                dir="right" 
                active = {position < (testimonials.length - 1)}
                style={{position:'absolute', right:0, top: width*0.25 + 40, opacity:0.8}}
                onClick={()=>{
                    if(position < (testimonials.length - 1))
                    {
                        let newPosition = position + 1
                        set({offset:  (width * 0.05) - (newPosition * TESTIMONIAL_MARGIN) - (newPosition * width * 0.9)})
                        setPosition(newPosition)
                    }
                }}    
            />
        </div>
)}

const Testimonial = ({data, index, position}) => {
    const {width, height} = useWindowDimensions()
    return(
        <div style={{height: width*0.6, display:'flex', borderRadius:20, background:"rgba(255,255,255, 0.8)", width: width*0.9, flexDirection:'row', marginRight:TESTIMONIAL_MARGIN}}>
            <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{display:'flex', alignItems:'center', flexDirection:'column', margin:"auto auto 10px 0px"}}>
                    <img
                        src={data.photo}
                        style={{objectFit:'cover', 
                        margin:'auto 10px auto'
                    }}
                        width={width*0.4}
                        height={width*0.4}
                    />
                    <img
                        src={Stars}
                        style={{objectFit:'cover', margin:'auto 10px auto', opacity:0.8}}
                        width={width*0.35}
                    />
                </div>
            </div>
            <div style={{display:'flex', flexDirection:'column', paddingTop:20, paddingBottom:20}}>
                {data.title && 
                    <div style={{fontWeight:"bold", fontSize:16, margin:2, opacity:0.8}}>
                        {data.title}
                    </div>
                }
                <div style={{overflow:"scroll", fontSize:12, paddingRight:10}}>
                    {data.message}
                </div>
                <div style={{fontWeight:"bold", fontSize:12, margin:2, opacity:0.8}}>
                    {data.name}
                </div>
                <div style={{fontWeight:"bold", fontSize:12, opacity:0.8}}>
                    {data.date}
                </div>

            </div>
        </div>
)
}

const Arrow = ({dir,onClick,active, style:_style}) => 
    active?
        <div 
            style={{
                fontSize:40,
                cursor:'pointer',
                width:40,
                height:40,
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                // borderRadius:15,
                // border: "1px solid " + BLUE,
                ..._style
            }}
            onClick = {onClick}    
        >
            {/* <FontAwesomeIcon 
                icon={dir=="right"?faAngleRight:faAngleLeft}
                color={BLUE}
                // color="white"
                size='xs'
            /> */}
            <img
                src={ArrowIcon}
                style={dir=="right"?{}:{transform:"rotate(180deg)"}}
                width={40}
            />

        </div>
    :null