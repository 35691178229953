import React, { useState } from 'react';
import RedShirt from '../images/store/redShirt.png'
import BlackShirt from '../images/store/blackShirt.jpg'
import p1 from '../images/store/p1.jpeg'
import p2 from '../images/store/p2.jpg'
import p3 from '../images/store/p3.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom'
import useWindowDimensions from '../helpers/useWindowDimensions.js'

const redShirtItem = {
    name:"Remember Everyone Deployed RED T-Shirt",
    price: 30,
    imageURL:RedShirt,
}
const blackShirtItem = {
    name:"Remember Everyone Deployed BLACK T-Shirt",
    price: 30,
    imageURL:BlackShirt,
}

export const Merch = ({useCart}) => {
    const [redSize,setRedSize] = useState('L')
    const [blackSize,setBlackSize] = useState('L')
    const {width} = useWindowDimensions()
    const mobile = width < 420
    const [cart,setCart] = useCart
    return(
    <div style={{width:'100%',display:'flex',flexDirection:'column',paddingBottom:100}}>
        <div style={{width:'100%',display:'flex',flexDirection:'column',height:mobile?200:260,backgroundColor:'lightGrey',alignItems:'center',justifyContent:'center',position:'relative'}}>
            <div style={{color:"#b61d24",fontSize:mobile?40:50,fontWeight:'bold',textAlign:'center'}}>
                MERCHANDISE
            </div>
            <div style={{fontSize:mobile?20:24,fontWeight:'bold',textAlign:'center',marginTop:20}}>
                With every purchase a gift is sent to a
            </div>
            <div style={{fontSize:mobile?20:24,fontWeight:'bold',textAlign:'center'}}>
                deployed troop
            </div>
            <Link to="cart">
                <div 
                    style={{
                        position:'absolute',
                        top:10,
                        right:25,
                        fontSize:25,
                        cursor:'pointer',
                        backgroundColor:"#18274e",
                        width:50,
                        height:50,
                        borderRadius:25,
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                    }}
                >
                    <FontAwesomeIcon 
                        icon={faShoppingCart}
                        color="white"
                    />
                </div>
                <div 
                    style={{position:'absolute',top:6,right:15,borderRadius:13,height:25,
                            width:25,backgroundColor:"#b61d24",color:'white',...center}}
                >
                    {Object.keys(cart).length}
                </div>
            </Link>
        </div>
        <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
            <div style={{flex:1}}>
                <img
                    src={p1}
                    style={{objectFit:'contain',width:'100%'}}
                    alt="soldier"
                />
            </div>
            <div style={{flex:1}}>
                <img
                    src={p2}
                    style={{objectFit:'contain',width:'100%'}}
                    alt="soldier"
                />
            </div>
            <div style={{flex:1}}>
                <img
                    src={p3}
                    style={{objectFit:'contain',width:'100%'}}
                    alt="soldier"
                />
            </div>
        </div>
        <div style={{flex:1, display:'flex', flexDirection:'row',padding:10}}>
            {/* <div style={{flex:1,display:'flex',flexDirection:'column',padding:5}}>
                <img
                    src={BlackShirt}
                    style={{width:'100%'}}
                    alt="Black shirt"
                />
                <div style={{fontSize:24,fontWeight:'bold',textAlign:'center',marginTop:10}}>
                    REMEMBER EVERYONE DEPLOYED
                </div>
                <div style={{fontSize:20,fontWeight:'bold',textAlign:'center'}}>
                    Black Unisex T-Shirt
                </div>
                <div style={{fontSize:20,fontWeight:'bold',textAlign:'center',color:'grey'}}>
                    $30.00
                </div>
                <select
                    onChange={e=>setBlackSize(e.target.value)}
                    value={blackSize}
                    style={{textAlign:'center', textAlignLast:'center',fontSize:16,marginTop:10}}
                >
                    <option value="S">Small</option>
                    <option value="M">Medium</option>
                    <option value="L">Large</option>
                    <option value="XL">XL</option>
                    <option value="2XL">2XL</option>
                </select>
                <div 
                    style={{height:34,borderRadius:5,backgroundColor:'#084b97',color:"white",marginTop:10,fontSize:14,textAlign:'center',cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'center'}}
                    onClick={()=>{
                        const id = "blackShirt" + blackSize
                        setCart({
                            ...cart,
                            [id]:{
                                item:{
                                    ...blackShirtItem,
                                    size:blackSize
                                },
                                id,
                                quantity:1,
                                num:1000,
                            }
                        })
                        alert("added to cart")
                    }}
                >
                    add to cart
                </div>
            </div> */}
            <div style={{flex:1,display:'flex',flexDirection:'column',padding:50}}>
                <img
                    src={RedShirt}
                    style={{width:'100%'}}
                    alt="red shirt"
                />
                <div style={{fontSize:24,fontWeight:'bold',textAlign:'center',marginTop:10}}>
                    REMEMBER EVERYONE DEPLOYED
                </div>
                <div style={{fontSize:20,fontWeight:'bold',textAlign:'center'}}>
                    RED Unisex T-Shirt
                </div>
                <div style={{fontSize:20,fontWeight:'bold',textAlign:'center',color:'grey'}}>
                    $30.00
                </div>
                <select
                    onChange={e=>setRedSize(e.target.value)}
                    value={redSize}
                    style={{textAlign:'center', textAlignLast:'center',fontSize:16,marginTop:10}}
                >
                    <option value="S">Small</option>
                    <option value="M">Medium</option>
                    <option value="L">Large</option>
                    <option value="XL">XL</option>
                    <option value="2XL">2XL</option>
                </select>
                <div 
                    style={{height:34,borderRadius:5,backgroundColor:'#084b97',color:"white",marginTop:10,fontSize:14,textAlign:'center',cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'center'}}
                    onClick={()=>{
                        const id = "redShirt" + redSize
                        setCart({
                            ...cart,
                            [id]:{
                                item:{
                                    ...redShirtItem,
                                    size:redSize
                                },
                                id,
                                quantity:1,
                                num:1000,
                            }
                        })
                        alert("added to cart")
                    }}
                >
                    add to cart
                </div>

            </div>
        </div>
    </div>
)}

const center = {display:'flex',alignItems:'center',justifyContent:'center'}