import React from 'react';
import brandon from '../images/brandon.jpg'

let titleStyle = {
    fontSize:32,
    backgroundColor:'white',
    marginLeft:20,
    color:"#18274e"
}

let wordsStyle = {
    fontFamily:'Roboto-medium',
    fontSize:20,
    margin:20,
    color:"#18274e"
}

export const Brandon = () => {
    return(     
<>
<div style={{padding:20,paddingTop:50}}>
    <div style={titleStyle}>
        <b>BRANDON'S MISSION</b>
    </div>
    <div style={wordsStyle}>
        My name is Brandon Dutkiewicz and my mission in life is to take care of the troops that are deployed overseas. I want them to know that they are not alone, and people (patriots) out there do support them, even if they can't get the support from their families back home. I founded "Support A Troop" the first-ever personalized mobile care package app. This is the first time a deployed troop can download a service to their phone tailored to them specifically, as an individual; and patriots can send what troops request right from their phone without ever leaving their home. 
    </div>
    <div style={wordsStyle}>
        I come from humble beginnings', a low-income household. I was raised by my single mom and I had three other siblings. When I was eight-teen I decided to join the Army for my country and my family. In 2016 when I was 21 years old, I was deployed to the war-torn country of Afghanistan, Helmand province. Many times, on my tour, we were rocketed and threatened by the locals; we all knew we were hated. The best escape we had from where we were was on Fridays when messages and packages came from back home. A lot of laughs and smiles were shared about who sent what, and why. Unfortunately, Week after week some of us couldn't share in the joy of being sent something from someone back home; I was one of those soldiers. It is tough not to see personal support from someone. To know that what you are doing and what you are sacrificing matters to somebody. That feeling of not having someone follows you beyond your deployment and into your life as a veteran, I know this from experience. So, In January of 2019 one year after being honorably discharged from the U.S Army, I left everything again (college, family, and friends) to build "Support A Troop". I signed up to go back to Afghanistan so I can begin on my mission to send out personalized care packages. I was there for 18 months building a mobile phone app and sending out care packages through a Facebook page I started. 
    </div>
    <div style={wordsStyle}>
        I'm back home now, and my dream is to make this my living, I enjoy seeing the smile on the faces of troops and I love knowing patriots support these men and women in green. It makes me feel like my services weren't for nothing and now I know in my heart, that someone somewhere did support me; even though I couldn't see them.
    </div>
</div>
{/* PHOTOS */}
<img 
    style={{
        width:'100%',
        objectFit:'contain',
    }}
    src={brandon} 
    alt={'image of Brandon'}
/>

</>

)
}