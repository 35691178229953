import React from 'react';
import aboutGradientImage from '../images/aboutGradientImage.png'
import Banner from '../images/about/AboutUs_Banner.png'

import {Link} from 'react-router-dom'

let titleStyle = {
    // fontFamily:'Roboto-black',
    fontSize:32,
    // textAlign:'center',
    backgroundColor:'white',
    // color:'#333333',
    marginLeft:20,
    color:"#18274e"
}

let wordsStyle = {
    fontFamily:'Roboto-medium',
    fontSize:20,
    // textAlign:'center',
    margin:20,
    // color:'#636363',
    color:"#18274e"
}

export const About = ({sv}) => {
    // let context = require.context('../images/thankyous', false)
    // let allImages = context.keys().map(k=>context(k))
    // let images1 = allImages.slice(0,11)
    // let images2 = allImages.slice(11,21)
    // let images3 = allImages.slice(21,32)
    // let banner = require('../images/about/the_organization.jpg')
    // let banner = require('../images/new2/org.jpg')
    return(     
<>
    <img 
        style={{
            width:'100%',
            objectFit:'contain',
        }}
        src={Banner} 
        alt={'banner'}
    />
    <div style={{height:20}}/>
    <div
        style={{
            padding:20
        }}
    >
        <div style={titleStyle}>
            <b>ABOUT US</b>
        </div>
        <div style={wordsStyle}>
            Support A Troop was founded by Brandon Dutkiewicz, a former member of the United States Armed Forces. He has spent more than 2 years deployed overseas. It was during this time that he recognized many issues that family, friends, and organizations were having with sending and receiving care packages. An abundance of multiple items received in care packages were being unused and tossed away, due to an over shipment of the same items, and lack of information with what a troop needs. Now, as a veteran, he has devoted himself to the start of this mission, bringing service to the ones who need it.
        </div>
        <div style={titleStyle}>
            <b>OUR MISSION</b>
        </div>
        <div style={wordsStyle}>
            Support A Troop is a care package service, that is bridging the gap between troops and patriots. We provide a cost-effective and convenient service that strives to boost the morale and lifestyle of troops overseas, ensuring that deployed troops never feel forgotten. 
        </div>
        <div style={titleStyle}>
            <b>WHAT WE DO</b>
        </div>
        <div style={wordsStyle}>
            We provide the answer to the question, “what do they need”? That question is on the mind of every family member, friend, loved one, and patriot who wants to support the brave men and women serving our country, overseas. We provide a service that boosts the morale of those deployed by allowing them to create their own “wish list” providing you with their exact wants and needs. Every care package sent to a troop is 100% what they want.
        </div>
        <div style={wordsStyle}>
            Learn how we further our mission, with the Support A Troop mobile care package app.
        </div>
            <div
                style={{
                    width:100,
                    height:27,
                    backgroundColor:"#233c7a",
                    borderRadius:4,
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    textAlign:'center',
                    color:'white',
                    fontSize:13,
                    marginLeft:20,
                    cursor:'pointer'
                }}
            >
                <Link to="mobile-app" style={{textDecoration:'none',color:'white',textAlign:'center'}}>
                    <div
                        onClick={()=>sv('mobile-app')}
                    >
                        CLICK HERE
                    </div>
                </Link>
            </div>
    </div>

    {/* PHOTOS */}
    <img 
        style={{
            // position:'relative',
            width:'100%',
            objectFit:'contain',
            marginBottom:0,
            paddingBottom:0
        }}
        src={aboutGradientImage} 
        alt={'image of troop'}
    />
</>
)}