import React, {useState} from 'react'
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom'
import { SpinnerRoundOutlined } from 'spinners-react';

export const Store = ({items,useCart,showTop=true}) => {
    const [cart] = useCart
    const [bank,setBank] = useState(0)
    const {width} = useWindowDimensions()
    const mobile = width < 420
    const numShown = mobile ? 2 : 4
    const getShown = () => items.filter((item,i)=> 
        ( i >= bank) && (i < (bank + numShown) ) && cart[item.id] == undefined
    )
    if(!items.length){
        return( 
            <div style={{width:"100%", display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', marginTop:50, marginBottom:50}}>
                <SpinnerRoundOutlined color="#18274e"/>
                <div style={{fontSize: 40,fontFamily:'Roboto',color:"#18274e",margin:50}}> 
                    LOADING STORE 
                </div>
                <SpinnerRoundOutlined color="#18274e"/>
            </div>
        )
    }
    return(
        <div style={{width:'100%',display:'flex',flexDirection:'column', alignItems:'center',position:'relative',marginBottom:30}}>
            {showTop &&
            <>
                <div 
                    style={{
                        fontSize:(width/20),
                        marginBottom:30,
                        marginTop:30,
                        color:"#18274e",
                        fontFamily:'Roboto',
                        fontSize: mobile ? 24 : 40,
                    }}
                >
                    SUPPORT A TROOP
                </div>
                <Link to="cart">
                    <div 
                        style={{
                            position:'absolute',
                            top:10,
                            right:mobile?10:100,
                            fontSize:25,
                            cursor:'pointer',
                            backgroundColor:"#18274e",
                            width:50,
                            height:50,
                            borderRadius:25,
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center'
                        }}
                    >
                        <FontAwesomeIcon 
                            icon={faShoppingCart}
                            color="white"
                        />
                    </div>
                    <div 
                        style={{position:'absolute',top:6,right:mobile?30:90,borderRadius:13,height:25,
                                width:25,backgroundColor:"#b61d24",color:'white',...center}}
                    >
                        {Object.keys(cart).length}
                    </div>
                </Link>
            </>
            }
            <div style={{width:'100%',display:'flex',position:'relative'}}>
                {
                    getShown().map(i=>
                        <Item 
                            key={i.id} 
                            i={i} 
                            useCart={useCart} 
                            reduceBank={()=>setBank(bank>0?bank-1:0)}
                        />
                    )
                }
                <div style={{position:'absolute',left:10,top:140,...center}}>
                    <Arrow 
                        dir="left" 
                        onClick={()=>setBank(bank>0?bank-1:0)} 
                        active={bank!=0}
                    />
                </div>
                <div style={{position:'absolute',right:10,top:140,...center}}>
                    <Arrow 
                        dir="right" 
                        onClick={()=>setBank(bank<items.length-5?bank+1:items.length-4)} 
                        active={bank!=items.length-4}
                    />
                </div>

            </div>
            {/* <div style={{display:'flex'}}>
                <Arrow 
                    dir="left" 
                    onClick={()=>setBank(bank>0?bank-1:0)} 
                    active={bank!=0}
                />
                <Arrow 
                    dir="right" 
                    onClick={()=>setBank(bank<items.length-5?bank+1:items.length-4)} 
                    active={bank!=items.length-4}
                />
            </div> */}
        </div>
    )
}

const Arrow = ({dir,onClick,active}) => 
    active?
        <div 
            style={{
                // margin:20,
                fontSize:40,
                backgroundColor:active?"#18274e":'grey',
                cursor:'pointer',
                width:40,
                height:40,
                borderRadius:20,
                border:'1px solid white',
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
            }}
            onClick = {onClick}    
        >
            {/* {dir=='left'?'<':'>'} */}
            <FontAwesomeIcon 
                icon={dir=="right"?faAngleRight:faAngleLeft}
                color="white"
            />

        </div>
    :null

const Item = ({i,useCart,reduceBank}) => {
    const {width} = useWindowDimensions()
    const mobile = width < 420
    const {num,item,id} = i
    const [cart,setCart,_,setSelected] = useCart
    const numShown = mobile ? 2 : 4
    const imgSize= mobile ? 3 : 6
    return(
        <div style={{width:width/numShown, ...center, flexDirection:'column'}}>
            <div style={{fontSize:20,margin:5,fontWeight:'bold',textAlign:'center'}}>
                {num + " troops are requesting this product"}
            </div>
            <Link to="/viewItem">
                <div 
                    style={{width:width/numShown,height:width/numShown,display:'flex', alignItems:'center',justifyContent:'center'}}
                    onClick={()=>{
                        // setTimeout(()=>window.scrollTo(0,0),100)
                        setSelected(i)
                    }}
                >
                    <img 
                        style={{width:width/imgSize}}    
                        src={item.imageURL} 
                    />
                </div>
            </Link>
            <div style={{height:40,fontSize:13,margin:18,fontWeight:'bold',textOverflow:'ellipsis'}}>
                {item.name}
            </div>
            <div style={{width:100,fontSize:12,margin:10,fontWeight:'bold',textAlign:'center',color:blue,backgroundColor:'white',zIndex:20}}>
                {"$" + item.price}
            </div>
            <div
                style={{width:100,height:30, backgroundColor:"#233c7a",...center, cursor:'pointer'}}
                onClick={()=>{
                    reduceBank()
                    setCart({...cart,[id]:{item,id,quantity:1}})
                }}
            >
                <div style={{color:'white',fontSize:12, textAlign:'center'}}>
                    ADD TO CART
                </div>
            </div>
        </div>
    )
}

const center = {display:'flex',alignItems:'center',justifyContent:'center'}
const blue = "#21376d"