export const comments = [

{c:"Sending a care package is so easy! I don’t even have to leave my house or worry if I got what they needed because I’m shipping exactly what they requested!"
 ,t:"– American Patriot"},

{c:"It’s really easy to use and a great way to get support when you’re based outside the U.S. Five stars all the way!!!"
 ,t:"– American Soldier"},

{c:"Great app for soldiers! Really helps them out. Nicely designed and great functionality. So glad this exists!"
 ,t:"– American Patriot"},

{c:"This is such a great idea! Pick personnel you want to support and send from their wish list. No more one size fits all care packages that go to waste."
 ,t:"– American Veteran"},

{c:"Great app for any us citizens overseas. Both military AND contractors can use it. They have a lot of options to choose from. 5 star!!"
 ,t:"– American Patriot"},

{c:"I think this is a really nice platform excited to get started!"
 ,t:"– American Patriot"},

{s:"Myself, and a few of my fellow Soldiers who are deployed with me, have received items from our wish list through Support A Troop. On our end, it’s super easy to sign up and we love being able to select the ...",
c:"Myself, and a few of my fellow Soldiers who are deployed with me, have received items from our wish list through Support A Troop. On our end, it’s super easy to sign up and we love being able to select the items we need. We receive the items fairly quick too! Thank you so much for making our deployment a little more comfortable by donating these items to us and all of the other deployed service members. We truly appreciate your organization and all of the donors who support us. :) Thank you for making it so easy and simple."
 ,t:"– American Soldier"},

{s:"During the pandemic currently going around the globe, I was unable to obtain a lot of the items I took for granted such as creatine and other various supplements that didn’t fall under the list of ...",
c:"During the pandemic currently going around the globe, I was unable to obtain a lot of the items I took for granted such as creatine and other various supplements that didn’t fall under the list of “essential” items, but through this app I was able to get what I needed to feel not as confined and restricted and to reach my over all goals of physical fitness. Everything came rather quickly and I love how I was able to Thank the generous donor who has gifted my such pleasantries. Thank you supportatroop for supporting this troop!!"
 ,t:"– American Solider"},

 {s:"I especially appreciate the message function where the Service member can send me a note indicating they’ve received the items I selected for them & can send me a phot. It has reassured my confidence in ...",
c:"I especially appreciate the message function where the Service member can send me a note indicating they’ve received the items I selected for them & can send me a phot. It has reassured my confidence in this program that my donation has actually been received – thank you for that functionality! And THANK YOU so much for creating this platform where we can give, we can choose from a recipients wish list & that the Service member has the ability to confirm its ben received. 💗💗💗 "
,t:"-American Patriot"},

{s:"This is such a wonderful app to send needed and earned items all across the world to out troops. Wish I was rich! I would give to each and every one of you! Stay strong and thank you from the bottom of my ...",
c:"This is such a wonderful app to send needed and earned items all across the world to out troops. Wish I was rich! I would give to each and every one of you! Stay strong and thank you from the bottom of my heart for your service and sacrifices. ❤️🇺🇸 "
 ,t:"- American Patriot "},

{s:"This app is the best way I found to help the military. I just downloaded it, it was so easy to use. I already sent out 2 care packages for under 20 dollars. I can’t wait to receive a photo back from them! ...",
c:"This app is the best way I found to help the military. I just downloaded it, it was so easy to use. I already sent out 2 care packages for under 20 dollars. I can’t wait to receive a photo back from them! So wonderful!"
 ,t:"-American Patriot "},


{s:"I’m an army mom who has been sending care packages through this app since it’s been released! It’s been so easy to use and so affordable. I like to help a deployed troop at least once a month! I highly ...",
c:"I’m an army mom who has been sending care packages through this app since it’s been released! It’s been so easy to use and so affordable. I like to help a deployed troop at least once a month! I highly recommend this app for supporting our troops!"
 ,t:"- Army Mom"},

]