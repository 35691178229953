export const PrivacyPolicyText =

`
INTRODUCTION

This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase or donation from Support A Troop. (the “Site”).

PERSONAL INFORMATION WE COLLECT
When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”.

We collect Device Information using the following technologies:
- “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
- “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
- “Web beacons”, “tags”, and “pixels” are electronic files used to record information about how you browse the Site.

Additionally when you make a donation or purchase or attempt to make a donation or purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers, email address, and phone number. We refer to this information as “Order Information”. 

When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.

HOW DO WE USE YOUR PERSONAL INFORMATION?
We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:
- Communicate with you;
- Screen our orders for potential risk or fraud; and
- When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.

We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns). 

SHARING YOUR PERSONAL INFORMATION
We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use stripe to collect donations and purchases--you can read more about how stripe uses your Personal Information here: https://stripe.com/privacy. We also use Google Analytics to help us understand how our customers use the Site -- you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.

Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.

BEHAVIORAL ADVERTISING
As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.

You can opt out of targeted advertising by using the links below:
- Facebook: https://www.facebook.com/settings/?tab=ads
- Google: https://www.google.com/settings/ads/anonymous
- Bing: https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads

Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: http://optout.aboutads.info/.

DO NOT TRACK
Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.

YOUR RIGHTS
If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.

Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.

DATA RETENTION
When you place an order or donation through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.

CHANGES
We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.

MINORS
The Site is not intended for individuals under the age of 18.

CONTACT US
For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e‑mail at support@supportatroop.com or by mail using the details provided below:

support@supportatroop.com
Support A Troop LLC
PO Box 1964 
Brooksville, FL 34605

`

export const AppPrivacyPolicyText = 
`
Welcome to the mobile application (as the case may be) (collectively, the "Platform") of  Support A Troop LLC  . (including its subsidiaries, affiliates, agents, and service providers, collectively, " Support A Troop LLC," "we," "us," and/or "our"). This Platform is operated by  Support A Troop LLC and has been created to provide information about our company, our mobile platform designed to permit users to create a profile to leave a wishlist fundraising campaign to accept physical donations, and our related services (together with the Platform, the "Services") to our Services users and visitors ("you," and/or "your"). Except as provided below, this Privacy Policy describes and governs how we collect, use, share and protect data collected from users of or visitors to the Platform.

Before you use or submit any information through or in connection with the Services, please carefully review this Privacy Policy. By using any part of the Services, you consent to the collection, use, and disclosure of your information as further outlined in this Privacy Policy.

 the data controller is  Support A Troop LLC. and can be reached at the address set out at "Contacting  Support A Troop LLC" below;
"information" covers all personal data processed by us which means information that (either in isolation or in combination with other information) enables you to be identified directly or indirectly; and
you will not need to consent to this Privacy Policy, but do acknowledge you have read and understood its terms.
Information We Collect
As further described below, when you use or otherwise access the Services, we may collect information in multiple ways, including when you provide information directly to us, and when we passively collect information from your browser or device.

Information That You Provide Directly to Us:
We collect information from you (including, but not limited to, your name, email address, mailing address, and title) when you voluntarily provide such information, such as when you contact us with inquiries, respond to one of our surveys, provide us with feedback, register for access to the Services, or use certain Services.

You may also provide us with information relating to third parties—including information stored in your email and mobile device address books—such as, without limitation, names, email addresses, and phone numbers of your contacts (collectively, “Third-Party Data”). The Services may request your permission to access such Third-Party Data.

You may also provide us with special categories of personal data under the GDPR (also known as sensitive data) about yourself and/or third parties, such as life style information. When you provide such information, we will seek your explicit consent unless it is being published in relation to your wishlist.

We collect this information for the purposes described below in the section entitled, Our Use of Information Collected. While you are under no obligation to directly provide us with such information, should you choose to withhold such information, we may not be able to provide you with some or all of the Services.

If you provide “personal data” (as defined under the Privacy Shield principles) to the Services, you acknowledge and agree that such personal data may be transferred from your current location to the offices and servers of  Support A Troop LLC and the authorized third parties referred to herein located in the United States.

Our Services may include online chats and message boards. Please remember that any information that is disclosed in these areas becomes public information for both us and other users to use and share. Please be considerate and respectful of others while using the community to share your opinions. We reserve the right, but do not have the obligation, to review and monitor such posting or any other content on our Services, and to remove postings or content that may be viewed as inappropriate or offensive to others.

Information That is Passively or Automatically Collected
Device & Usage Information:
When you interact with  Support A Troop LLC through the Services, we automatically receive and store certain information from devices that you use to access the Services. This information is collected passively using various technologies, and includes the type of internet browser or mobile device you use, any website from which you have come to the Services, your operating system, and may include location data through an IP address that identifies the city and state where you logged into the Platform or your precise geo-location if you have permitted your mobile device to provide that information to us.  Support A Troop LLC may store such information itself or such information may be included in databases owned and maintained by  Support A Troop LLC affiliates, agents or service providers.

Aggregated Data:
In an ongoing effort to better understand and serve the users of the Services,  Support A Troop LLC often conducts research on its customer demographics, interests and behavior based on information we collect. We may de-identify and/or aggregate the information collected through the Services for research purposes or any other purposes, and we may share this data with our affiliates, agents, business partners, or other third parties.

Location Information:
When you use the Services to organize a Wish list, the Services may request that you provide your city or town and state or province of residence. Please keep in mind that other users of the Services may be able to view your city or town and state or province of residence in connection with the Wish List, if it is made available. We may also use your location information in an aggregate way, as described above in the "Aggregated Data" section.

Cookies and Other Electronic Technologies:
When you interact with the Services, we try to make that experience simple and meaningful. When you visit our Platform, our web server sends a cookie to your computer or mobile device. Cookies are small pieces of information that are issued to your computer or mobile device when you visit a website or access or use a mobile application, and that store and sometimes track information about your use of the Platform. A number of cookies we use last only for the duration of your web or application session and expire when you close your browser or exit the application. Other cookies are used to remember you when you return to the Platform and will last longer.

Some of the cookies used by the Platform are set by us, and some are set by third parties who are delivering services on our behalf.

We may use cookies, and similar technologies to cookies, to collect information about your access and use of the Platform. For example, we may use cookies or other technologies that: (1) allow you to navigate and use all the features provided by our Platform; (2) customize elements of the layout and/or content within the Platform and remember that you have visited us before; (3) identify the number of unique visitors we receive; (4) allow us to provide you with a customized experience; and (5) inform us how you use the Platform (including how long you spend on the Platform) and where you have come to the Platform from, so that we can improve the Platform and learn which functions of the Platform are most popular with users. As we adopt additional technologies, we may also gather additional information through other methods.

Most web and mobile device browsers automatically accept cookies but, if you prefer, you can change your browser to prevent that or to notify you each time a cookie is set. You can also learn more about cookies by visiting www.allaboutcookies.org, which includes additional useful information on cookies and how to block cookies using different types of browsers or mobile devices.

You may also consult the "Help" section of your browser for more information (e.g., Internet Explorer; Google Chrome; Mozilla Firefox; or Apple Safari). You can also manage the use of Flash technologies, including cookies and local storage objects with the Flash management tools available at Adobe's website. Please note that by blocking any or all cookies, you may not have access to certain features or offerings of the Services.

If you are accessing our Services through a mobile device, you can also update your privacy settings on your device by setting the “Limit Ad Tracking” and Diagnostics and Usage setting property located in the settings screen of your Apple iPhone or iPad, or by resetting your Android ID through apps that are available in the Play Store. You can also limit information collection by uninstalling the App on your device and you can use the standard uninstall process available as part of your device for this purpose.

Information From Social Networking Services
One of the special features of the Services is that it may allow you to enable or log into the Services via various social networking services like Facebook or Twitter ("Social Networking Service(s)"). By directly integrating these services, we aim to make your online experiences richer and more personalized. To take advantage of this feature, we will ask you to provide your credentials for the applicable Social Networking Service in order to log-in to our Services. When you add a Social Networking Services account to the Services or log into the Services using your Social Networking Services account, we will collect relevant information necessary to enable the Services to access that Social Networking Service and your data contained within that Social Networking Service. As part of such integration, the Social Networking Service will provide us with access to certain information (including, for example, your name and photograph, as well as other information that you permit the Social Network Service to share with us) that you have provided to the Social Networking Service, and we will use, store, and disclose such information in accordance with this Privacy Policy. However, please remember that the manner in which Social Networking Services use, store, and disclose your information is governed by the policies of such third parties, and  Support A Troop LLC shall have no liability or responsibility for the privacy practices or other actions of any Social Networking Services that may be enabled within the Services.

You may also have the option of posting your Services activities to Social Networking Services when you access content through the Services (for example, you may post to Facebook that you performed an activity on the Platform); you acknowledge that if you choose to use this feature, your friends, followers, and subscribers on any Social Networking Services you have enabled will be able to view such activity.

Please visit the privacy policy of the relevant Social Networking Service to find out more information about your rights and choices.

Our Use of Information Collected
 Support A Troop LLC uses the information collected from the Services in a manner that is consistent with this Privacy Policy. If you start a Wish List, we may reach out to you to provide helpful tips or advice in connection with your Wish List. If you provide information for a certain reason, we may use the information in connection with the reason for which it was provided. For instance, if you contact us by email, we will use the information you provide to answer your question or resolve your problem. Also, if you provide information about yourself, or have provided (or otherwise permitted the Services to access) any Third-Party Data, we will use your information and any such Third-Party Data to provide you with access to such services and to monitor your use of such services. For the purposes of the GDPR, we may use your information in the above ways as this is necessary for us to fulfill our obligations under our contract with you or in order to take steps at your request prior to entering into a contract.

 Support A Troop LLC may also use the information we collect through the Services to help us in providing the Services and support services, processing transactions, fulfilling requests for information, receiving and sending communications, updating marketing lists, analyzing data and research aimed at improving the accuracy, effectiveness, usability or popularity of the Services, to personalize the content and advertising that you see on Services and to understand how users interact with and user our advertisement and Services, to associate your activities, customize content or tailor advertising across different device and browsers that you use to access the Services (for instance, computers, tablets, mobile devices, applications and websites), to comply with state, local, federal or international laws, to enforce our Terms of Service and this Privacy Policy, as otherwise described in this Privacy Policy, or in assisting with other tasks from time to time. Please note that we may combine information that we collect from you and about you (including automatically-collected information) with information we obtain about you from our affiliates and/or non-affiliated third parties, and use such combined information in accordance with this Privacy Policy. For the purposes of the GDPR, we may use your information in the above ways as this is necessary: (i) to fulfill our obligations under our contract with you or in order to take steps at your request prior to entering into a contract; (ii) for our legitimate interest to maintain our relationship with you or protect you and us against fraud; or (iv) to comply with legal obligations.

We may also use the information collected from the “Information We Collect” section, such as device identifiers and MAC addresses, to associate users’ activities, customize content, or tailor advertising to those users across different devices and browsers (for instance, tablets, mobile devices and applications, and websites). For the purposes of the GDPR, we may use your information in the above ways as this is necessary: (i) to fulfil our obligations under our contract with you or in order to take steps at your request prior to entering into a contract; (ii) for our legitimate interest to maintain our relationship with you or protect you and us against fraud; or (iv) to comply with legal obligations.

 Support A Troop LLC may use the information collected through the Services to contact you about services we believe will be of interest to you. In some cases, we will seek your permission. In each marketing communication we send you will contain instructions permitting you to "opt-out" of receiving future marketing communications. In addition, if at any time you wish not to receive any future marketing communications or you wish to have your name deleted from our mailing lists, please contact us as indicated below. If you opt out of receiving marketing communications or other information we think may interest you, as discussed below, we may still send you emails about your account or any Services you have requested or received from us.

We may also use data collected through the Services to send communications relating to the Services, in accordance with applicable laws. For example, we may send periodic emails to registered users of the Services relating to their recent interactions with the Services, such as donating to a wish list or "hearting" a wish list. Also, if you use any feature of the Services permitting you to communicate with third parties (such as to refer a third party to the Services or to communicate with them regarding a wish list or a donation), either by submitting Third-Party Data to the Services or otherwise permitting the Services to automatically access Third-Party Data in your possession, you acknowledge and agree that you have the authority of the relevant third party for us to access and use the relevant Third-Party Data and that you have notified these third parties and informed them how their information is collected and used by  Support A Troop LLC to provide the Services. We reserve the right to identify you as the person who has made the referral in any messages that are sent to them. We may use Third-Party Data to (1) contact such third party using the Third-Party Data provided, and/or (2) provide you with an editable template message designed to facilitate communication between you and such third party through the Services. In addition to sending the foregoing communications, we may also send reminders or related messages to you and to third parties on your behalf from time to time where permitted by applicable law. In each case, any such communications sent to third parties using Third-Party Data will provide a means to "opt-out" of receiving further communication of the same nature.

Mobile Services: If you access the Services through a mobile device, you agree that we may communicate with you regarding  Support A Troop LLC by SMS, MMS, text message, or other electronic means to your mobile device. In the event you change or deactivate your mobile telephone number, you agree to promptly update your  Support A Troop LLC account information to ensure that your messages are not misdirected. Please note that your wireless service carrier’s standard charges, data rates, and other fees may apply where you access the Services through a mobile device. In addition, downloading, installing, or using certain Services on a mobile device may be prohibited or restricted by your carrier, and not all Services may work with all carriers or devices. By using the Services on a mobile device, you agree that we may communicate with you regarding  Support A Troop LLC by SMS, MMS, text message, or other electronic means to your mobile device. In the event you change or deactivate your mobile telephone number, you agree to promptly update your  Support A Troop LLC account information to ensure that your messages are not misdirected.

Our Disclosure of Information Collected Through the Services
There are certain circumstances in which we may share information collected through the Services with certain third parties without further notice to you, as set forth below:

Business Transfers:
As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution, similar event, or steps taken in anticipation of such events (e.g., due diligence in a transaction), user information may be part of the transferred assets.

Agents, Consultants, Vendors and Related Third Parties:
 Support A Troop LLC, like many businesses, sometimes hires other companies to perform certain business-related functions on its behalf, and we may provide access to or share your information with these companies so they can perform services for us. Examples of such functions include marketing, mailing information, data storage, security, fraud preventions, payment processing, legal services, and maintaining databases. 



Legal Requirements:
 Support A Troop LLC may access, disclose, transfer and preserve your information when we have a good faith belief that doing so is necessary to: (i) comply with the law including with subpoenas, search warrants, court orders, and other legal process; and respond to inquiries or requests from government, regulatory, law enforcement, public authorities, or content protection organizations; (ii) protect and defend the legal rights, privacy, safety or property of  Support A Troop LLC, its affiliates, subsidiaries, employees, agents, contractors, or its users; (iii) permit us to pursue available remedies, commence, participate in, or defend litigation, or limit the damages we may sustain; or (iv) enforce this Privacy Policy or any applicable Terms of Service.

Wish List Organizers
If you donate to a Wish list, we may share your name, email address and other information you’ve provided in connection with your donation with the Wish List organizer, who may contact you. By donating to the Wish List, you consent to our sharing the above information and to the Wish List organizer contacting you.

Your consent
We may share your information in the event that you consent to the sharing of your information. For example, we may partner with third party organizations to arrange for specific Wish Lists. If you consent to our delivering your contact information to a specific partner, we may share, with the partner organization, your name, email address and other information you’ve provided in connection with your donation to the specific Wish List.

Aggregate and/or De-Identified Information
We may aggregate and/or de-identify your information so that the information no longer relates to you individually. Our use and disclosures of such aggregated or de-identified information is not subject to this Privacy Policy, and may be disclosed to others without limitation and for any purpose.

Analytics
We may use third-party web analytics services on our Services, such as those of Google Analytics. These service providers use the sort of technology previously described in the “Cookies and other electronic technologies” section to help us analyze how users use the Services, including by noting the third-party website from which you arrive, and provide certain features to you. The information (including your IP address) collected by the technology will be disclosed to or collected directly by these service providers, who use the information to evaluate your use of the Services. This information may be used to place interest-based advertisements on the Platform. This may result in you seeing advertisements for our Platform when you visit other websites. To prevent Google Analytics from using your information for analytics, you may install the Google Analytics Opt-out Browser.

Tailored Advertising
We may use third-party advertising technologies that allow for the delivery of relevant content and advertising on our Platform, as well as on other websites you visit. The ads may be based on various factors such as the content of the page you are visiting, information you enter such as your age and gender, your searches, demographic data, user-generated content, and other information we collect from you. These ads may be based on your current activity or your activity over time and across other websites and online services and may be tailored to your interests.

Also, third parties whose products or services are accessible or advertised on our Platform may place cookies or other tracking technologies on your computer, mobile phone, or other device to collect information about your use of the website as discussed above. We also allow other third parties (e.g., ad networks and ad servers such as Google Analytics, DoubleClick and others) to serve tailored ads to you on our website, other sites, and in other applications, and to access their own cookies or other tracking technologies on your computer, mobile phone, or other device you use to access the website. We may provide our customer information (such as a hashed version of your email addresses) to service providers, who may “match” this information in de-identified form to cookies (or mobile ad identifiers) and other proprietary IDs, in order to target or “retarget” you with ads when you visit other websites and mobile applications. (You may opt out of these many of these service providers as described in the next paragraph.)

We neither have access to, nor does this Privacy Policy govern, the use of cookies or other tracking technologies that may be placed on your computer, mobile phone, or other device you use to access our website by non-affiliated, third-party ad technology, ad servers, ad networks or any other non-affiliated third parties. Those parties that use these technologies may offer you a way to opt out of ad targeting as described below.

If you receive tailored advertising on your computer through a web browser, you can learn more about such tailored browser advertising and how you can generally control cookies from being put on your computer to deliver tailored advertising, by visiting the European Interactive Digital Advertising Alliance Consumer Ad Choices link for EU based advertising or for US based advertising either: (i) the Network Advertising Initiative’s Consumer Opt-Out link or (ii) the Digital Advertising Alliance’s Consumer Opt-Out link to opt-out of receiving tailored advertising from companies that participate in those programs. To opt out of Google Analytics for display advertising or customize Google display network ads, you can visit the Google Ads Settings page.

Please note that to the extent advertising technology is integrated into our website or other websites that you visit, you may still receive advertisements even if you opt-out. In that case, the advertising will not be tailored to your interests. Also, we do not control any of the above opt-out links or whether any particular company chooses to participate in these opt-out programs. We are not responsible for any choices you make using these mechanisms or the continued availability or accuracy of these mechanisms.

Unsolicited Information
Please be advised that some information you provide may be publicly accessible, such as information posted in forums or comment sections. We may also collect information through customer support communications, your communication to us of ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, with publicly-accessible information, "Unsolicited Information"). By sending us Unsolicited Information, you further (a) agree that we are under no obligation of confidentiality, express or implied, with respect to the Unsolicited Information, (b) acknowledge that we may have something similar to the Unsolicited Information already under consideration or in development, (c) grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide license to use, modify, prepare derivative works, publish, distribute and sublicense the Unsolicited Information, and (iv) irrevocably waive, and cause to be waived, against  Support A Troop LLC and its users any claims and assertions of any moral rights contained in such Unsolicited Information. This Unsolicited Information section shall survive any termination of your account or the Services. This Privacy Policy otherwise does not apply to any information collected by  Support A Troop LLC other than information collected through the Services.

Children
Our Services are not designed for use by individuals under the age of 13. If you are under the age of 13, please do not use the Services and/or submit any information through the Services. If you have reason to believe that a child under the age of 13 has provided personal information to  Support A Troop LLC through the Services, please contact us a support@supportatroop.com , and we will delete that information from our databases to the extent required by law.

Links to Other Web Sites
This Privacy Policy applies only to the Services. The Services may contain links to other web sites not operated or controlled by  Support A Troop LLC (the "Third-Party Sites"). The policies and procedures we described here do not apply to the Third-Party Sites. The links from the Services do not imply that  Support A Troop LLC endorses or has reviewed the Third-Party Sites. We suggest contacting those sites directly for information on their respective privacy policies.

Security
 Support A Troop LLC has implemented technical and organizational security measures to protect the Personal Data provided via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. For example, limiting access to information only to employees and authorized service providers who need to know such information for the purposes of this Privacy Notice. However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from the Services may not be secure. Therefore, you should take special care in deciding what information you send to us via email. Please keep this in mind when disclosing any information to  Support A Troop LLC via the Internet.

Do-Not-Track Signals
Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. We do not recognize or respond to browser-initiated DNT signals, as the Internet industry is currently still working toward defining exactly what DNT means, what it means to comply with DNT, and a common approach to responding to DNT.

Retention of your information
We will only retain the information for as long as necessary for the purpose for which that information was collected and to the extent permitted or required by applicable laws. When we no longer need to use your information, we will remove it from our systems and records and/or take step to anonymize it so that you can no longer be identified from it in accordance with our internal document retention policies.

Access to Information
You may access and correct certain information about you by visiting the “Account Settings” portion of our Platform. You may also request in writing copies of personal information about you held by us. If that information is inaccurate, please let us know and we will endeavor to make the necessary amendments, erase, or block the relevant information as you request.


If you have any questions or complaints about  Support A Troop LLCs privacy practices, you may contact us at the email address or mailing address set forth under “Contacting  Support A Troop LLC.” We will work with you to resolve your issue.


Other Terms and Conditions
Your access to and use of the Services is subject to the Terms of Service, which may be made available to you in connection with your use of the Services.

Changes to ‘ Support A Troop LLCs's Privacy Policy
The Services and our business may change from time to time. As a result, at times it may be necessary for  Support A Troop LLC to make changes to this Privacy Policy.  Support A Troop LLC reserves the right to update or modify this Privacy Policy at any time and from time to time. If you disagree with our revisions to the Privacy Policy, you may de-activate your account or discontinue the use of our Services. Please review this policy periodically, and especially before you provide us with any information through the Services. This Privacy Policy was last updated on the date indicated above. Your continued use of the Services after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.


Contacting  Support A Troop LLC
Please also feel free to contact us if you have any questions about  Support A Troop LLC's Privacy Policy or the information practices of the Services.

You may contact us as follows:

PO Box 1964
Brooksville Fl, 34605

support@supportatroop.com


`