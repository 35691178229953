import React, {useEffect, useRef} from 'react';
// import flag from '../images/flag_hires.jpg'
import flag from '../images/new/banner.jpg'
import useWindowDimensions from '../helpers/useWindowDimensions.js'

export const Header = ({sethh,sv,v}) => {
    const {width} = useWindowDimensions()
    const ref = useRef(null)
    useEffect(()=>sethh(ref.current.clientHeight),[width,sethh])

    return(
        <>
        <div 
            style={{
                width:'100%',
                backgroundColor:'white',
                position:'sticky',
                top:0,
                zIndex:-1
            }}
            ref={ref}
        >

            {/* FLAG BANNER */}

            <div style={{
                width:'100%',
                overflow:'hidden',
            }}>
                <img
                    style={{width:'100%'}}
                    src={flag} 
                    alt="flag" 
                />
            </div>
        </div>
        </>
    )
}