import React, {useState, useEffect} from 'react';
import {getStripeToken, onToken} from '../helpers/getStripeToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { colors } from '../assets/colors.js';
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import {useHistory} from 'react-router-dom'

const gray = "#828384"
const blue = "#18274e"
const red = "#b62d24"

const center = {alignItems:'center',justifyContent:'center'}
const row = {display:'flex',flexDirection:'row'}
const column = {display:'flex',flexDirection:'column'}
const field = {
    width:200,
    margin:5,
    height:24,
    border:`2px solid ${blue}`,
    borderRadius:8,
    padding:4,
    paddingLeft:6,
    fontSize:12,
}
const nums = x => Array.from(x).filter(c=>c===' ' || (c>="0" && c<="9")).join('')

export const Donation = ({sv,setShowThanks}) => {
    const {width} = useWindowDimensions()
    const mobile = width < 450
    const [number,setNumber] = useState('')
    const [exp,setExp] = useState('')
    const [cvc,setCvc] = useState('')
    const [firstName,setFirstName] = useState('')
    const [lastName,setLastName] = useState('')
    const [email,setEmail] = useState('')
    const [monthly,setMonthly] = useState(false)
    const [loading,setLoading] = useState(false)
    const [amount,setAmount] = useState(0)
    const [customAmount,setCustomAmount] = useState('')
    const [step,setStep] = useState(1)
    const [anim,setAnim] = useState(true)
    const history = useHistory()
    useEffect(()=>{
        let i = setInterval(()=>setAnim(!anim),1000)
        return(()=>clearInterval(i))
    })
    return(
        <div style={{
            padding:0,
            width:mobile?'100%':260,
            height:420,
            // height:360,
            ...center,
            ...column,
            zIndex:15,
            boxShadow:mobile?undefined: '0px 0px 10px 3px #000000',
            borderRadius:mobile?3:6,
            overflow:'hidden'
        }}>
            {loading &&
                <div style={{
                    width:mobile?'100%':260,
                    height:360,
                    position:'absolute',
                    backgroundColor:'white',
                    ...center,
                    ...column,
                    userSelect:'none',
                    opacity:0.8
                }}>
                    <div style={{
                        transition:'opacity 0.7s',
                        opacity: anim ? 1 : 0.3,
                        textAlign:'center',
                        ...center,
                        ...column,
                    }}>
                        Please Wait, Your Request is Processing
                    </div>
                </div>

            }
            {/* BANNER */}
            <div style={{
                width:'100%',
                textAlign:'center',
                fontSize:20,
                padding:10,
                userSelect:'none',
                backgroundColor:"#ededed",
                color: "#b61d24"
            }}>
                {step===1 && <b>Support Our Troops</b>}
                {step!==1 && <b>{`You're Donating\n $${amount || customAmount}`}</b>}
            </div>
            <div style={{
                width:'100%',
                height:'100%',
                // ...center,
                paddingTop:20,
                ...column,
                backgroundColor:'white',
                alignItems:'center'
            }}>

                {/* 3 BALLS */}

                <div style={{
                    // border:'1px solid black',
                    width:'100%',
                    height:60,
                    ...row,
                    ...center
                }}>
                    {
                        ['Amount','Info','Payment'].map((x,i)=>
                        <div key={i} style={{
                            ...center,
                            ...row
                        }}>
                            <div style={{
                                ...center,
                                ...column,
                                fontSize:10,
                                margin:8,
                                userSelect:'none'
                            }}>
                                <b>{x}</b>
                                <div 
                                    style={{
                                        ...center,
                                        ...column,
                                        width:30,
                                        height:30,
                                        borderRadius:30,
                                        backgroundColor:step===i+1 ? gray : blue,
                                        fontSize:13,
                                        color:'white',
                                        boxSizing:'border-box',
                                        userSelect:'none',
                                        cursor: i+1<step ? 'pointer' : 'arrow',
                                        marginTop:5
                                    }}
                                    onClick={()=>i+1<step && setStep(i+1)}
                                >
                                    {i+1}
                                </div>
                            </div>
                            {i<2 && 
                                <FontAwesomeIcon 
                                    icon={faLongArrowAltRight} 
                                    size="2x"
                                    style={{
                                        transform:'translateY(5px)',
                                        opacity:0.5
                                    }}
                                />
                            }
                        </div>
                        )
                    }
                </div>

                <div style={{
                    width:260,
                    height:200,
                    display:'flex',
                    flexWrap:'wrap',
                    ...row,
                    ...center,
                }}>
                    {step===1 &&

                    // AMOUNTS

                    <>
                        {[1,5,10,20,30,40,50,75,100].map((x,i)=>
                            <div
                                key={i}
                                style={{
                                    width:64,
                                    height:30,
                                    backgroundColor:amount===x?red:blue,
                                    borderRadius:8,
                                    color:'white',
                                    fontSize:14,
                                    margin:8,
                                    ...center,
                                    ...row,
                                    // border:amount===x ? '3px solid #F2B52E' : 0,
                                    boxSizing:'border-box',
                                    cursor:'pointer',
                                    userSelect:'none'
                                }}
                                onClick={()=>{
                                    setAmount(x)
                                    setCustomAmount('')
                                }}
                            >
                                {`$${x}`}
                            </div>    
                        )}
                        <div style={{
                            fontSize:16,
                            userSelect:'none',
                            color:gray
                        }}>
                            {' $ '}
                            <input
                                style={field}
                                type='number'
                                placeholder='Other'
                                onChange={e=>{
                                    setCustomAmount(e.target.value)
                                    setAmount(0)
                                    setMonthly(false)
                                }}
                                value={customAmount}
                            />
                        </div>
                        <div style={{
                            width:'100%',
                            ...center,
                            ...row,
                            fontSize:11,
                            margin:4,
                            color:colors.medGray,
                            userSelect:'none'
                        }}>
                            <b>MAKE A MONTHLY DONATION </b>
                            <input
                                type="checkbox"
                                checked={monthly}
                                onChange={e=>{
                                    if(customAmount===''){
                                        setMonthly(e.target.checked)
                                    } else {
                                        alert("We're sorry, custom donation amounts cannot be set to monthly. If you want to make a monthly donation, please select one of the pre-set amounts.")
                                    }
                                }}
                                style={{
                                    // transform:'scale(1.2)',
                                    marginLeft:10,
                                    cursor:'pointer'
                                }}
                            />
                        </div>
                    </>
                    }
                    {step===2 &&
                        <form>
                            <div style={{
                                width:'100%',
                                ...center,
                                ...column
                            }}>
                                {/* FIRST NAME */}
                                <input
                                    type='text'
                                    placeholder='First Name'
                                    onChange={e=>setFirstName(e.target.value)}
                                    value={firstName}
                                    style={field}
                                    spellCheck="false"
                                />
                                {/* LAST NAME */}
                                <input
                                    type='text'
                                    placeholder='Last Name'
                                    onChange={e=>setLastName(e.target.value)}
                                    value={lastName}
                                    style={field}
                                    spellCheck="false"
                                />
                                {/* EMAIL */}
                                <input
                                    required
                                    type='email'
                                    placeholder='Email Address'
                                    onChange={e=>setEmail(e.target.value)}
                                    value={email}
                                    style={field}
                                />
                            </div>
                        </form>
                    }
                    {step===3 &&
                        <form>
                            <div style={{
                                ...column,
                                ...center
                            }}>
                                {/* NUMBER */}
                                <input
                                    type='text'
                                    placeholder='Credit Card Number'
                                    onChange={e=>setNumber(nums(e.target.value.replace(/ /g, '').replace(/(.{4})/g, '$1 ').trim().substring(0,19)))}
                                    value={number}
                                    style={field}
                                />
                                <div style={{
                                    ...row,
                                    ...center
                                }}>
                                    {/* EXPIRY */}
                                    <input
                                        type='text'
                                        placeholder='MM/YY'
                                        onChange={e=>setExp(nums(e.target.value).replace(/ /g, '').replace(/\//g, '').replace(/(.{2})/g, '$1 / ').substring(0,7))}
                                        value={exp}
                                        style={{...field,width:90}}
                                        onKeyDown={e=>{
                                            if(e.keyCode===8){
                                                setExp('')
                                            }
                                        }}
                                    />
                                    {/* CVC */}
                                    <input
                                        type='text'
                                        placeholder='CVC'
                                        onChange={e=>setCvc(nums(e.target.value.trim().substring(0,3)))}
                                        value={cvc}
                                        style={{...field,width:90}}
                                    />
                                </div>
                            </div>
                        </form>
                    }
                </div>

                    {/* NEXT BUTTON */}
                <div style={{
                    width:'100%',
                    height:60,
                    marginTop:20,
                    ...center,
                    ...row,
                    // border:'1px solid black'
                }}>
                    <div
                        style={{
                            width:160,
                            height:35,
                            backgroundColor:red,
                            borderRadius:8,
                            color:'white',
                            fontSize:12,
                            userSelect:'none',
                            cursor:'pointer',
                            ...row,
                            ...center
                        }}
                        onClick={async ()=>{
                            if(step===1){
                                if(amount === 0 && customAmount === ''){
                                    alert('Please choose an amount or enter a custom amount')
                                } else {
                                    setStep(step+1)
                                }
                            } else if (step===2){
                                if(!validEmail(email) && monthly){
                                    alert('Please enter a valid email address')
                                } else {
                                    setStep(step+1)
                                }
                            } else if (step === 3){
                                setLoading(true)
                                let token = await getStripeToken({number,exp,cvc})
                                if(token.error){
                                    setLoading(false)
                                    alert(token.error.message)
                                } else {
                                    let res = await onToken({
                                        token:token.id,
                                        amount:amount || customAmount ,
                                        firstName,
                                        lastName,
                                        email,
                                        monthly
                                    })
                                    if(res.err){
                                        console.log(res.err)
                                        setLoading(false) 
                                        if(res.err.param=== "email"){
                                            setStep(2)
                                        }
                                        alert(res.err.message)
                                    }else if(res.status==="SUCCESS"){
                                        setLoading(false) 
                                        // alert(`Thank you for your ${monthly ? 'monthly dontion' : 'donation'} of $${amount ? amount.toFixed(2) : parseFloat(customAmount).toFixed(2)}!`)
                                        sv('home')
                                        setShowThanks({show:true,firstName,lastName,email,amount,customAmount,monthly})
                                        history.push("/home")
                                    } else {
                                        setLoading(false)
                                        setStep(2)
                                        alert('Something went wrong! Please try again!')
                                        console.log(res)
                                    }
                                }                                                 
                            }
                        }}
                    >
                        <b>{step<3 ? 'Next' : 'Submit'}</b>
                    </div>
                </div>
            </div>
        </div>
)}

function validEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}