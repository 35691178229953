import React from 'react';
import {PrivacyPolicyText} from '../assets/privacyPolicyText.js'

export const Privacy = () =>
<div style={{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
}}>
    <div style={{
        fontSize:28,
        textAlign:'center',
        margin:24,
        fontWeight:'bold'
    }}>
        Privacy Policy
    </div>
    <div style={{
        margin:45,
        marginTop:10,
        whiteSpace:'pre-wrap',
    }}>
        {addBlue(PrivacyPolicyText)}
    </div>
</div>

const addBlue = text =>{
    var t1 = text.split('\n')
    var lines = []
    var line = ''
    for(let i=0;i<t1.length;i++){
        if(isCaps(t1[i])){
            lines.push(line)
            lines.push(t1[i])
            line=''
        }else {
            line += t1[i] + '\n'
        }
        if(i==t1.length-1){
            lines.push(line)
        }
    }
    return lines.map(x=>!isCaps(x) ?
        <div>{x}</div> :
        <div style={{color:'blue',textAlign:'center',marginTop:'20px',marginBottom:'7px'}}>{x}</div>)
}

const isCaps = t => Array.from(t).every(x=>x==x.toUpperCase())