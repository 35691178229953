import React, {useState, useEffect} from 'react';
import {Header} from './components/header.js'
import {Footer} from './components/footer.js'
import {Menu} from './components/menu.js'
import {DonationThanks} from './views/donationThanks'
import {Home} from './views/home.js'
import {About} from './views/about.js'
import {Brandon} from './views/brandon.js'
import {Merch} from './views/merch.js'
import {CommingSoonSoldier} from './views/comingSoonSoldier.js'
import {Features} from './views/features.js'
import {MobileApp} from './views/mobileApp.js'
import {MobileAppTroop} from './views/mobileAppTroop.js'
import {Donate} from './views/donate.js'
import {Sponsors} from './views/sponsors.js'
import {ContactUs} from './views/contactUs.js'
import {Links} from './views/links.js'
import {WaysToSupport} from './views/waysToSupport.js'
import {Privacy} from './views/privacy.js'
import { AppPrivacy } from './views/app_privacy.js';
import {TermsAndConditions} from './views/termsAndConditions.js'
import {ViewItem} from './views/viewItem.js'
import {BrowserRouter as Router,Switch,Route,useLocation} from "react-router-dom";
import {useStore} from './helpers/useStore.js'
import {Cart} from './views/cart.js'
import Checkout from './views/checkout.js'
import AppDonationPortal from './views/appDonationPortal.js'
import CheckForAppDonation from './components/checkForAppDonation.js'

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  const [hh,sethh] = useState(0)
  const [v, setv] = useState('home')
  const [showThanks,setShowThanks] = useState({show:false})
  // const [showThanks,setShowThanks] = useState({
  //   show:true,
  //   firstName:"Andrew",
  //   lastName:"March",
  //   email:"youthclubrecords@gmail.com",
  //   amount:105.25,
  //   customAmount:undefined,
  //   monthly:true
  // })
  const [appDonation, setAppDonation] = useState(false)
  const [items, setItems] = useState([])
  const useCart = useStore()
  const useNote = useState(false)
  const [cart,_] = useCart
  const [merch,setMerch] = useState([])

  // useEffect(()=>{
  //     // console.log('fetching')
  //     fetch('/fetchWebStoreData',
  //     // fetch('https://us-central1-care-c7a53.cloudfunctions.net/fetchWebStoreData',
  //     {
  //         headers: {'Content-Type': 'application/json'},
  //         method:"POST",
  //         body:JSON.stringify({num:25})
  //     })
  //     .then(res=>{
  //       return res.json()
  //     })
  //     .then(json=>{
  //       // console.log(json)
  //       setItems(json)
  //     })
  //   },
  //   []
  // )

  const sv=x=>{
    if(window.scrollY>hh){
      window.scrollTo(0,hh)
    }
    setv(x)
  }
  if(showThanks.show)
  {
    return (
      <Router>
        <ScrollToTop/>
        {/* <Header sethh={sethh} v={v} sv={sv}/> */}
        <Menu v={v} sv={sv} hh={hh}/>
        <DonationThanks sv={sv} setShowThanks={setShowThanks} showThanks={showThanks}/>
        <Footer sv={sv} v={v}/>
      </Router>
    )
  }
  if(appDonation){
    return(
      <Router>
        <ScrollToTop/>
        {/* <Header sethh={sethh} v={v} sv={sv}/> */}
        <Menu v={v} sv={sv} hh={hh}/>
        <AppDonationPortal setShowThanks={setShowThanks} appDonation={appDonation} sv={sv}/>
        <Footer sv={sv} v={v}/>
      </Router>
    )
  }
  return (
    <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column'}}>
      <Router>
        <ScrollToTop/>
        <CheckForAppDonation setAppDonation={setAppDonation}/>
        <Switch>
          <Route 
            exact
            path={[
              '/','/home','/sponsors','/termsAndConditions','/privacy','/app-privacy','/ways-to-support',
              '/mobile-app','/mobile-app-troop','/mobile-app-donor','/contact-us','/about',
              '/brandon','/waysToSupport','/features','/soldier-of-the-week','/store',
            ]}
          >
            {/* <Header sethh={sethh} v={v} sv={sv}/> */}
            <Menu v={v} sv={sv} hh={hh}/>
          </Route>
        </Switch>
        <div style={{width:'100%',backgroundColor:'white',overflow:'visible',height:'100%',flex:1, margin:0, padding:0}}>
          <Switch>
            {/* <Route path='/payment-portal'>
              <AppDonationPortal
                setShowThanks = {setShowThanks}
              />
            </Route> */}
            <Route path='/checkout'>
              <Checkout
                useCart={useCart} 
                useNote = {useNote}
                setShowThanks = {setShowThanks}
              />
            </Route>
            <Route path='/viewItem/:id'>
              <ViewItem
                useCart={useCart} 
                items={items.filter(x=>cart[x.id]==undefined)}
              />
            </Route>
            <Route exact path='/viewItem'>
              <ViewItem
                useCart={useCart} 
                items={items.filter(x=>cart[x.id]==undefined)}
              />
            </Route>
            <Route path='/cart'>
              <Cart
                useCart={useCart} 
                items={items}
                useNote = {useNote}
              />
            </Route>
            <Route path='/termsAndConditions'>
              <TermsAndConditions/>
            </Route>
            <Route path='/privacy'>
              <Privacy/>
            </Route>
            <Route path='/app-privacy'>
              <AppPrivacy/>
            </Route>
            <Route path='/ways-to-support'>
              <WaysToSupport  sv={sv}/>
            </Route>
            <Route path='/mobile-app'>
              <MobileApp sv={sv}/>
            </Route>
            <Route path='/mobile-app-donor'>
              <MobileApp sv={sv}/>
            </Route>
            <Route path='/mobile-app-troop'>
              <MobileAppTroop sv={sv}/>
            </Route>
            <Route path='/mobileAppTroop'>
              <MobileAppTroop sv={sv}/>
            </Route>
            <Route path='/waysToSupport'>
              <WaysToSupport/>
            </Route>
            <Route path='/contact-us'>
              <ContactUs  sv={sv}/>
            </Route>
            <Route path='/soldier-of-the-week'>
              <CommingSoonSoldier/>
            </Route>
            {/* <Route path='/store'>
              <Merch useCart={useCart}/>
            </Route> */}
            <Route path='/sponsors'>
              <Sponsors  sv={sv}/>
            </Route>
            <Route path='/features'>
              <Features  sv={sv}/>
            </Route>
            {/* <Route path='/donate'>
              <Donate sv={sv} setShowThanks={setShowThanks}/>
            </Route> */}
            <Route path='/about'>
              <About sv={sv}/>
            </Route>
            <Route path='/brandon'>
              <Brandon sv={sv}/>
            </Route>
            <Route path='/links'>
              <Links sv={sv}/>
            </Route>
            <Route path='/'>
              <Home 
                items={items.filter(x=>cart[x.id]==undefined)}
                useCart={useCart}
                sv={sv}
              />
            </Route>
          </Switch>
        </div>
        <Switch>
          <Route 
            exact
            path={[
              '/','/home','/donate','/sponsors','/termsAndConditions','/privacy','/ways-to-support',
              '/mobile-app','/mobile-app-troop','/mobile-app-donor','/contact-us','/about',
              '/brandon','/waysToSupport','/features','/soldier-of-the-week',
              // '/store',
            ]}
          >
            <Footer sv={sv} v={v}/>
          </Route>
        </Switch>

      </Router>
    </div>
  );
}

// const CheckForAppDonation = ({setAppDonation}) => {
//   console.log(useLocation())
//   const location = useLocation()
//   if(location.pathname=="/payment-portal"){
//     console.log('portal')
//     const query = new URLSearchParams(useLocation().search)
//     console.log(query.get('name'))
//     const uid = query.get('uid')
//     const amount = query.get('amount')
//     const tier = query.get('tier')
//     const name = query.get('name')
//     setAppDonation({uid,amount,tier,name})
//   }
//   return null
// }

export default App;