import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useRef, useState, useEffect, useCallback } from 'react';

export default function useHover() {
  const [value, setValue] = useState(false);
  const [oldValue, setOldValue] = useState(false);

  const ref = useRef(null);

  const check = useRef(null)

  const handleMouseOver = () => {
      setValue(true)
      setOldValue(true)
  }
  const handleMouseOut = () => {
    //   setValue(false);
    setOldValue(false)
    setTimeout(()=>check.current(),200)
  }

  useEffect(()=>{
    check.current=()=>{
        if(!oldValue){
            setValue(false)
        }
    }
  },[oldValue])

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
          // autoCancel && setValue(false)
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
}