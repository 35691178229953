import React, {useState} from 'react';
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faStar  } from "@fortawesome/free-solid-svg-icons";
import {animated,useSpring} from 'react-spring'
import {Link} from "react-router-dom";

import banner from '../images/banners/MobileApp_Banner.png'

import i1 from '../images/phoneMocksTroop/1.png'
import i2 from '../images/phoneMocksTroop/2.png'
import i3 from '../images/phoneMocksTroop/3.png'
import i4 from '../images/phoneMocksTroop/4.png'
import i5 from '../images/phoneMocksTroop/5.png'

const blue = "#18274e"
const red = "#b62d24"

const fadeIndex=['a','b','c']
const fades = [
    {a:1,b:0,c:0},
    {a:0,b:1,c:0},
    {a:0,b:0,c:1},
]

export const MobileAppTroop = ({sv}) => {
    const {width} = useWindowDimensions()
    const [viewing,setViewing] = useState(0)
    const mobile = width < 520
    const [props, set] = useSpring(()=>({from: {a:1,b:0,c:0}, config: {friction: 60}}))

    return(
<div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>

    {/* BANNER` */}
    <div style={{width:'100%',position:'relative'}}>
        <img 
            style={{
                width:'100%',
                objectFit:'contain',
            }}
            src={banner} 
            alt={'banner'}
        />
        <div 
            style={{position:"absolute",top:'55%',left:"28%",height:'27%',width:'21%', cursor:'pointer',zIndex:10}}
            onClick={()=>window.open("https://apps.apple.com/ca/app/isupport-care-package-builder/id1495734737", "_blank")}
        />
        <div 
            style={{position:"absolute",top:'55%',left:"49%",height:'27%',width:'24%', cursor:'pointer',zIndex:10}}
            onClick={()=>window.open("http://play.google.com/store/apps/details?id=com.iSupportDeployedTroops.iSupportCarePackageBuilder", "_blank")}
        />
    </div>
    {/* TITLE */}

        <div style={{
            fontFamily:'Roboto',
            fontSize:mobile ? 40 : width < 600 ? 32 : 40,
            marginLeft:50,
            color:red,
            marginRight:'auto',
            marginTop:40
        }}>
            <b>For the deployed troop</b>
        </div>
            <div style={{margin:30,marginBottom:0}}>
        <div style={title}>
            ABOUT
        </div>
        <div style={text}>
            The Support A Troop app is a mobile military care package app. As a troop you can download the app to receive care packages from those who care back home. 
        </div>
        <div style={text}>
            The Support A Troop app allows you to create a profile, add a bio about yourself, and create a wish list of items that you need to make your deployment more comfortable.
        </div>
        <div style={text}>
            Donors will be able to see your profile, and can choose to follow you, to keep up with items you add to your wish list.
        </div>
        <div style={text}>
            You will be notified, through the app when you receive a care package and when your care package gets shipped.
        </div>
        <div style={text}>
            Once your care package has been delivered, you have the ability to send your donor a photo with a thank you message, thanking them for their support.
        </div>

        <div style={title}>
            WHY DOWNLOAD OUR APP?
        </div>
        <div style={text}>
            The Support A Troop app is the future of care package delivery. 
        </div>
        <div style={text}>
            We are here to help you during your deployment, and for others to show that they support our military, and those who serve. This service was created for you, so you never feel left out.
        </div>

    </div>

    {/* 3 SCREEEN GRABS */}

    <div style={{
        width:'100%',
        display:'flex',
        justifyContent:'center',
        position:'relative',
        cursor:'pointer',
    }}>
        <div 
            style={{
                cursor:'pointer',
                width:30,
                zIndex:10,
                flexGrow:10,
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
            }}
            onClick={()=>{
                if(viewing>0){
                    set(fades[viewing-1])
                    setViewing(viewing-1)
                }
            }}   
        >
            {viewing>0 &&
                <FontAwesomeIcon 
                    icon={faChevronCircleLeft} 
                    size="2x"
                    color='#18274e'
                />
            }
        </div>

    {/* DUMMY */}

        {
            [i1,i2,i3].map((photo,i)=>
                <div key={i} style={{
                    width:'30%',
                    margin:2,
                    borderRadius:mobile ? 10 : 30,
                    overflow:'hidden',
                    opacity:0
                }}>
                    <img 
                        style={{
                            width:'100%',
                            objectFit:'contain',
                        }}
                        src={photo} 
                        alt={'ty1'}
                    />
                </div>
        )}

    {/* REAL ONES */}

        {
            [[i1,i2,i3],[i2,i3,i4],[i3,i4,i5]]
            .map((x,j)=>
                <div key={j} style={{
                    display:'flex',
                    flexDirection:'row',
                    position:'absolute',
                    left:0,
                    top:0,
                }}>
                    <div style={{width:40}}/>
                    {
                        x.map((photo,i)=>
                            <animated.div key={i} style={{
                                width:'30%',
                                margin:2,
                                borderRadius:mobile ? 10 : 20,
                                overflow:'hidden',
                                opacity:props[fadeIndex[j]]
                            }}>
                                <img 
                                    style={{
                                        width:'100%',
                                        objectFit:'contain',
                                    }}
                                    src={photo} 
                                    alt={'ty1'}
                                />
                            </animated.div>
                    )}
                    <div style={{width:40}}/>
                </div>
            )
        }
        <div 
            style={{
                cursor:'pointer',
                width:30,
                zIndex:10,
                flexGrow:10,
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
            }}
            onClick={()=>{
                if(viewing<2){
                    set(fades[viewing+1])
                    setViewing(viewing+1)
                }
            }}
        >
            {viewing<2 &&
                <FontAwesomeIcon 
                    icon={faChevronCircleRight} 
                    size="2x"
                    color='#18274e'
                />
            }
        </div>
    </div>

    {/* FOOTER */}
    <div 
        style={{
            width:'90%',
            backgroundColor:red,
            borderRadius:10,
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            marginTop:12
        }}
    >
        {!mobile && 
            <Stars/>
        }
        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <div style={{color:'white',fontSize:13,textAlign:'center',marginLeft:10,marginRight:10}}>
                If you are a <b>PATRIOT</b> looking
            </div>
            <div style={{color:'white',fontSize:13,textAlign:'center',marginLeft:10,marginRight:10}}>
                to send a care package to a deployed troop
            </div>
        </div>
        <Link to="mobile-app-donor" style={{textDecoration:'none'}}>
            <div 
                style={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    justifyContent:'center',
                    backgroundColor:"#233c7a",
                    color:'white',
                    borderRadius:6,
                    padding:15,
                    paddingTop:10,
                    paddingBottom:10,
                    margin:10,
                    fontSize:12,
                    textAlign:'center',
                    cursor:'pointer'
                }}
                // onClick={()=>sv('mobile-app-donor')}
            >
                CLICK HERE
            </div>
        </Link>
        {!mobile && 
            <Stars/>
        }
    </div>

    <div style={{height:100}}/>
</div>
)}

const Stars = ({left,nine}) => 
    <div
        style={{
            flex:1,
            flexDirection:'row',
            display:'flex',
            alignItems:'center',
            justifyContent:'space-around',
            paddingLeft:20,
            paddingRight:20,
            fontSize:30
        }}
    >
        {
            Array(4).fill().map((x,i)=>
                <FontAwesomeIcon key={i} icon={faStar} style={{opacity:'0.5'}} size="xs" color="white"/>
            )
        }
    </div>

const title = {
    fontSize:20,
    color:blue,
    fontFamily:'Roboto-medium',
    margin:20,
    marginTop:30
}
const text = {
    fontFamily:'Roboto-medium',
    margin:20,
    marginBottom:0,
    fontSize:14
}
// import React, {useState} from 'react';
// import useWindowDimensions from '../helpers/useWindowDimensions.js'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faChevronCircleRight, faChevronCircleLeft  } from "@fortawesome/free-solid-svg-icons";
// import {animated,useSpring} from 'react-spring'

// import banner from '../images/mobileAppPageTroop/banner.jpg'
// import ios_store from '../images/ios_store.jpg';
// import android_store from '../images/android_store.jpg';

// import i13 from '../images/newMobileAppTroop/i13.jpg'
// import i11 from '../images/newMobileAppTroop/i11.jpg'
// import i9 from '../images/newMobileAppTroop/i9.jpg'
// import i14 from '../images/newMobileAppTroop/i14.jpg'
// import i10 from '../images/newMobileAppTroop/i10.jpg'
// import i12 from '../images/newMobileAppTroop/i12.jpg'

// const fadeIndex=['a','b']
// const fades = [
//     {a:1,b:0},
//     {a:0,b:1},
//     {a:0,b:0},
//     // {a:1,b:0,c:0},
//     // {a:0,b:1,c:0},
//     // {a:0,b:0,c:1},
// ]

// export const MobileAppTroop = () => {
//     const {width} = useWindowDimensions()
//     const [viewing,setViewing] = useState(0)
//     const mobile = width < 420
//     const [props, set] = useSpring(()=>({from: {a:1,b:0,c:0}, config: {friction: 60}}))

//     return(
// <>

// {/* TITTLE AND APP STORES */}

// <img 
//     style={{
//         width:'100%',
//         objectFit:'contain',
//     }}
//     src={banner} 
//     alt={'banner'}
// />

// <div style={{height:20}}/>
// <div style={{
//     width:'100%',
//     display:'flex',
//     flexDirection:mobile?'column':'row',
//     justifyContent:'space-evenly',
//     marginTop:30,
//     marginBottom:30,
// }}>

//     {/* TITLE */}

//     <div style={{
//         flex:3,
//         display:'flex',
//         flexDirection:mobile?'column':'row',
//         justifyContent:'space-evenly',
//         fontFamily:'Roboto',
//         fontSize: mobile ? 40 : width < 600 ? 32 : 40,
//         marginLeft:30,
//         marginBottom:10,
//         color:'#002167'
//     }}>
//         <div style={{width:'100%'}}><b>Create.</b></div>
//         <div style={{width:'100%'}}><b>Share.</b></div>
//         <div style={{width:'100%'}}><b>Receive.</b></div>
//     </div>

//     {/* APP STORES */}

//     <div style={{
//         flex:1,
//         display:'flex',
//         flexDirection:'column',
//         alignItems:'flex-start',
//         justifyContent:'flex-start',
//         marginLeft:mobile?30:0
//     }}>
//         <img 
//             style={{
//                 width:120,
//                 objectFit:'contain',
//                 borderRadius:10,
//                 cursor:'pointer',
//             }}
//             src={ios_store} 
//             alt={"ios_store"}
//             onClick={()=>window.open("https://apps.apple.com/ca/app/isupport-care-package-builder/id1495734737", "_blank")}
//         />
//         <img 
//             style={{
//                 width:120,
//                 marginTop:10,
//                 objectFit:'contain',
//                 borderRadius:15,
//                 cursor:'pointer',
//             }}
//             src={android_store} 
//             alt={"android_store"}
//             onClick={()=>window.open("http://play.google.com/store/apps/details?id=com.iSupportDeployedTroops.iSupportCarePackageBuilder", "_blank")}
            
//         />
//     </div>
// </div>

// {/* BLURB */}

// <div style={{
//     fontFamily:'Roboto-medium',
//     fontSize:24,
//     margin:30,
//     whiteSpace:'pre-wrap',
//     color:'#505050'
// }}>
//     {
//         "It's tough being away from home. We want to make it easier for you, your friends, and your family." +
//         "\n\nDownload our mobile app to provide an insight on the items you need while your over seas. Simply make a list of the items you'd like to receive and share the list with friends and family to support you. Encourage them to share it with their friends and family for additional support!"
//     }
// </div>
// <div style={{
//     fontFamily:'Roboto-medium',
//     fontSize:24,
//     margin:30,
//     whiteSpace:'pre-wrap',
//     color:'#002167'
// }}>
//     By making a profile, it is possible to receive items from your wish list, donated to you by public patriotic supporters, and from our organization through public donations.
// </div>
// <div
//     style={{
//         width:'100%',
//         display:'flex',
//         flexDirection:'row',
//     }}
// >
//     <div style={{
//         flex:1,
//         fontFamily:'Roboto',
//         textDecoration:'underline',
//         fontSize:mobile?14:20,
//         margin:30,
//         marginLeft:50
//     }}>
//         <b>App Preview</b>
//     </div>
// </div>

// {/* 3 SCREEEN GRABS */}

// <div style={{
//     width:'100%',
//     display:'flex',
//     justifyContent:'center',
//     position:'relative',
//     cursor:'pointer',
// }}>
//     <div 
//         style={{
//             opacity:0.4,
//             cursor:'pointer',
//             width:30,
//             zIndex:10,
//             flexGrow:10,
//             display:'flex',
//             alignItems:'center',
//             justifyContent:'center'
//         }}
//         onClick={()=>{
//             if(viewing>0){
//                 set(fades[viewing-1])
//                 setViewing(viewing-1)
//             }
//         }}   
//     >
//         {viewing>0 &&
//             <FontAwesomeIcon 
//                 icon={faChevronCircleLeft} 
//                 size="2x"
//             />
//         }
//     </div>

// {/* DUMMY */}

//     {
//         [i13,i11,i9].map((photo,i)=>
//             <div key={i} style={{
//                 width:'30%',
//                 margin:2,
//                 borderRadius:mobile ? 10 : 30,
//                 overflow:'hidden',
//                 opacity:0
//             }}>
//                 <img 
//                     style={{
//                         width:'100%',
//                         objectFit:'contain',
//                     }}
//                     src={photo} 
//                     alt={'ty1'}
//                 />
//             </div>
//     )}

// {/* REAL ONES */}

//     {
//         [
//             [i13,i11,i9],
//             [i14,i10,i12],
//             // [i77,i7,i7]
//         ]
//         .map((x,j)=>
//             <div key={j} style={{
//                 display:'flex',
//                 flexDirection:'row',
//                 position:'absolute',
//                 left:0,
//                 top:0,
//             }}>
//                 <div style={{width:40}}/>
//                 {
//                     x.map((photo,i)=>
//                         <animated.div key={i} style={{
//                             width:'30%',
//                             margin:2,
//                             borderRadius:mobile ? 10 : 20,
//                             overflow:'hidden',
//                             opacity:props[fadeIndex[j]]
//                         }}>
//                             <img 
//                                 style={{
//                                     width:'100%',
//                                     objectFit:'contain',
//                                 }}
//                                 src={photo} 
//                                 alt={'ty1'}
//                             />
//                         </animated.div>
//                 )}
//                 <div style={{width:40}}/>
//             </div>
//         )
//     }
//     <div 
//         style={{
//             opacity:0.4,
//             cursor:'pointer',
//             width:30,
//             zIndex:10,
//             flexGrow:10,
//             display:'flex',
//             alignItems:'center',
//             justifyContent:'center'
//         }}
//         onClick={()=>{
//             if(viewing<1){
//                 set(fades[viewing+1])
//                 setViewing(viewing+1)
//             }
//         }}
//     >
//         {viewing<1 &&
//             <FontAwesomeIcon 
//                 icon={faChevronCircleRight} 
//                 size="2x"
//             />
//         }
//     </div>
// </div>
// <div style={{height:40}}/>
// <div style={{
//     fontSize:12,
//     color:'#B0B0B0',
//     whiteSpace:'pre-wrap',
//     margin:10,
//     fontFamily:'Roboto'
// }}>
//     {
//         "This mobile app is owned by iSupport LLC, All rights to this app are reserved to iSupport LLC."+
//         "\niSupport LLC has partnered with iSupport Deployed Troops inc. to support our mission."
//     }
// </div>
// </>
// )}
