import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'

const shirts = [
    {
        id:"redShirtLarge"
    }
]

export const Cart = ({useCart,items:allItems,useNote}) => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    const [cart,setCart] = useCart
    const [addNote,setAddNote] = useNote
    // const storeItems = allItems.filter(x=>cart[x.id]==undefined)
    // const items = [...storeItems,...shirts]
    const items = allItems.filter(x=>cart[x.id]==undefined)
    const subtotal = Object.values(cart)
        .map(i=> i.quantity * parseFloat(i.item.price) )
        .reduce((p,c)=>p+c,0)
        .toFixed(2)
    if(items.length==0){return null}
    return (
        <div style={{width:'100%',display:'flex',flexDirection:'column',minHeight:600}}>
            <div style={{margin:16}}>
                {"subtotal : $" + subtotal}
            </div>
            <div style={{display:'flex',flexDirection:'row',marginLeft:12}}>
                <input 
                    type="checkbox"
                    checked={addNote}
                    onChange={e=>setAddNote(!addNote)}
                />
                <div style={{marginLeft:10}}>
                    {" Add a note"}
                </div>
            </div>
            {Object.keys(cart).length == 0 &&
                <div style={{margin:10,height:30,cursor:'pointer',backgroundColor:'#22376c',...center}}>
                    <div style={{color:'grey'}}>
                        Proceed to checkout
                    </div>
                </div>
            }
            {Object.keys(cart).length>0 &&
                <Link to="checkout">
                    <div style={{margin:10,height:30,cursor:'pointer',backgroundColor:'#22376c',...center}}>
                        <div style={{color:'white'}}>
                            Proceed to checkout
                        </div>
                    </div>
                </Link>
            }
            <div style={{margin:10,border:'1px solid #eeeeee'}}/>
            {
                Object.keys(cart)==0 && <div style={{margin:20,fontSize:12,fontWeight:'bold'}}>CART EMPTY</div>
            }
            {getCart(cart).map(i=>
                <CartItem 
                    key={i.id}    
                    useCart={useCart} 
                    i={i} 
                    num={getNum(i,allItems)}
                />
            )}
        </div>
    )
}
const getNum = (i,items) => 
    items.filter(x=>x.id == i.id).length ?
        ([0].num || 10 ) :
        10
const getCart = cart => Object.values(cart)
    .filter(x=>x!=undefined)
    .sort((a,b)=>a.item.name > b.item.name ? 1 : -1)

const CartItem = ({useCart,i,num}) => {
    const {quantity,item,id} = i
    const [cart,setCart] = useCart
    return(
        <div style={{width:'100%',display:'flex',flexDirection:'column'}}>
            <div style={{flex:1,display:'flex',flexDirection:'row'}}>
                <img
                    style={{margin:10,marginLeft:20,width:50,height:50,objectFit:'contain'}}
                    src={item.imageURL}
                />
                <div style={{margin:10,flex:1,flexDirection:'column',fontSize:12}}>
                    <div>
                        {item.name}
                    </div>
                    <div style={{color:blue,fontSize:12}}>
                        {"$ "+item.price}
                    </div>
                    {item.size &&
                        <div style={{color:blue,fontSize:12}}>
                            {"size: "+item.size}
                        </div>
                    }
                </div>
            </div>
            <div style={{marginLeft:20,flex:1,display:'flex',flexDirection:'row'}}>
                <div style={{width:w*3, border:'1px solid black',display:'flex',flexDirection:'row'}}>
                    <div 
                        style={{width:w, height:h, ...center, borderRight:'1px solid black', 
                            backgroundColor:grey,cursor:'pointer'}}
                        onClick={()=>setCart({
                            ...cart,
                            [id]:{
                                ...cart[id],
                                quantity:parseInt(i.quantity) - 1 || 1
                            }
                        })}
                    >
                        <div style={{fontSize:12}}>
                            {"-"}
                        </div>
                    </div>
                    <div style={{width:w, height:h, ...center}}>
                        <div style={{fontSize:12}}>
                            {quantity}
                        </div>
                    </div>
                    <div 
                        style={{width:w, height:h, ...center, borderLeft:'1px solid black',
                            backgroundColor:grey,cursor:'pointer'}}
                        onClick={()=>setCart({
                            ...cart,
                            [id]:{
                                ...cart[id],
                                quantity:i.quantity < num ? parseInt(i.quantity) + 1 : i.quantity
                            }
                        })}
                        >
                        <div style={{fontSize:12}}>
                            {"+"}
                        </div>
                    </div>
                </div>
                <div 
                    style={{marginLeft:15,width:80,height:h,backgroundColor:grey,border:'1px solid black',
                            ...center,cursor:'pointer'}}
                    onClick={()=>{
                        let newCart = {...cart}
                        delete newCart[id]
                        setCart(newCart)
                    }}
                >
                    <div style={{fontSize:12}}>
                        delete
                    </div>
                </div>
            </div>
            <div style={{margin:10,flex:1,border:'1px solid #eeeeee'}}/>
        </div>
    )
}
const w = 25
const h = 20
const grey = "#eff0f3"
const center = {display:'flex',alignItems:'center',justifyContent:'center'}
const blue = "#21376d"