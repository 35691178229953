import React, {useState,useEffect} from 'react';
import {withRouter} from 'react-router-dom'
import {getStripeToken, onTokenPurchase} from '../helpers/getStripeToken.js'
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import logo from '../images/logos/logo.png'
import logoText from '../images/logos/SupportaTroop_Logo_BlackTxt.png'
import {STATES} from '../assets/states.js'

const nums = x => Array.from(x).filter(c=>c===' ' || (c>="0" && c<="9")).join('')
const _alert = x => setTimeout(()=>alert(x),200)
const Checkout = ({useCart,useNote,history,setShowThanks}) => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    const {width} = useWindowDimensions()
    const mobile = width < 420
    const [cart,setCart] = useCart
    const [addNote] = useNote
    const [firstName,setFirstName] = useState("")
    const [lastName,setLastName] = useState("")
    const [email,setEmail] = useState("")
    const [state,setState] = useState("Texas")
    const [address,setAddress] = useState("")
    const [zip,setZip] = useState("")
    const [note,setNote] = useState("")
    const [cardNumber,setCardNumber] = useState("")
    const [exp,setExp] = useState("")
    const [cvc,setCvc] = useState("")
    const [page,setPage] = useState(0)
    const [loading,setLoading] = useState(false)
    const [anim,setAnim] = useState(true)
    useEffect(()=>{
        let i = setInterval(()=>setAnim(!anim),1000)
        return(()=>clearInterval(i))
    })

    return(
        <div style={{width:'100%',display:'flex',flexDirection:mobile?'column':'row',flex:1,height:'100%',minHeight:600}}>
            {loading &&
                <div style={{
                    width:'100%',
                    height:'100%',
                    position:'absolute',
                    backgroundColor:'white',
                    ...center,
                    userSelect:'none',
                    opacity:0.8
                }}>
                    <div style={{
                        transition:'opacity 0.7s',
                        opacity: anim ? 1 : 0.3,
                        textAlign:'center',
                        ...center,
                    }}>
                        Please Wait, Your Request is Processing
                    </div>
                </div>
            }

            <div style={{flex:1,display:'flex',flexDirection:'column',alignItems:'center',borderRight:'1px solid grey'}}>
                <div style={{margin:20,display:'flex',flexDirection:'row', alignItems:'center',justifyContent:'center'}}>
                    <img
                        src={logo}
                        style={{width:80}}
                    />
                    <img
                        src={logoText}
                        style={{width:80}}
                    />
                </div>
                <div style={{display:'flex',flexDirection:'row',...center, fontSize:12}}>
                    <div 
                        onClick={()=>history.push('cart')}
                        style={{marginLeft:5,color:lightBlue,cursor:'pointer'}}
                    >
                        {"cart >"} 
                    </div>
                    <div 
                        onClick={()=>page == 1 && setPage(0)}
                        style={{color:page?lightBlue:'black',marginLeft:5,cursor:'pointer'}}
                    >
                        {"information  > "}
                    </div>
                    <div style={{marginLeft:5,color:page?'black':'grey'}}>
                        {"payment "}
                    </div>
                </div>
                {page == 0 &&
                    <div style={{width:'90%',display:'flex',alignItems:'center',marginTop:20,flexDirection:'column'}}>
                        <div style={{marginRight:'auto'}}>
                            Contact Information
                        </div>
                        <input 
                            type="text"
                            placeholder="Email"
                            style={{width:'100%',...inputStyle,marginTop:20}}
                            value={email}
                            onChange={e=>setEmail(e.target.value)}
                        />
                        <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:10}}>
                            <div style={{flex:1,marginRight:10}}>
                                <input 
                                    type="text"
                                    placeholder="First Name"
                                    style={{width:'100%',...inputStyle}}
                                    value={firstName}
                                    onChange={e=>setFirstName(e.target.value)}
                                />
                            </div>
                            <div style={{flex:1}}>
                                <input 
                                    type="text"
                                    placeholder="Last Name"
                                    style={{width:'100%',...inputStyle}}
                                    value={lastName}
                                    onChange={e=>setLastName(e.target.value)}
                                />
                            </div>
                        </div>
                        <input 
                            type="text"
                            placeholder="Address"
                            style={{width:'100%',...inputStyle,marginTop:20}}
                            value={address}
                            onChange={e=>setAddress(e.target.value)}
                        />

                        <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:10}}>
                            <div style={{flex:1,marginRight:10}}>
                                <select
                                    onChange={e=>setState(e.target.value)}
                                    value={state}
                                    style={{width:'100%',...inputStyle, color:'grey'}}
                                >
                                    {STATES.map(x=>
                                        <option value={x}>{x}</option>    
                                    )}
                                </select>
                            </div>
                            <div style={{flex:1}}>
                                <input 
                                    type="text"
                                    placeholder="Zip Code"
                                    style={{width:'100%',...inputStyle}}
                                    value={zip}
                                    onChange={e=>setZip(e.target.value)}
                                />
                            </div>
                        </div>
                        {addNote &&
                            <div style={{width:'100%',marginTop:20,display:'flex',flexDirection:'column'}}>
                                <div style={{width:'100%',marginBottom:10,display:'flex',flexDirection:'row'}}>
                                    <div style={{marginRight:'auto'}}>
                                        Personal Note:
                                    </div>
                                    <div style={{}}>
                                        {`${note.length}/150 characters`}
                                    </div>
                                </div>
                                <textarea
                                    style={{width:'100%',height:200}}
                                    type="text"
                                    placeholder=""
                                    value={note}
                                    onChange={e=>note.length < 150 && setNote(e.target.value)}
                                >
                                </textarea>
                            </div>
                        }
                        <div 
                            style={{width:'95%',height:25,marginTop:20, marginBottom:20,
                                backgroundColor:lightBlue,borderRadius:3,...center,cursor:'pointer'}}
                            onClick={()=>{
                                if(!validEmail(email)){
                                    _alert("email address is not valid")
                                } else if( !email.length || !firstName.length || !lastName.length)
                                {
                                    _alert("please fill out all the fields")
                                } else {
                                    setPage(1)
                                }
                            }}
                        >
                            <div style={{color:'white'}}>
                                NEXT
                            </div>
                        </div>
                    </div>
                }
                {page == 1 &&
                    <div style={{width:'90%',display:'flex',alignItems:'center',marginTop:20,flexDirection:'column'}}>
                        <div style={{marginRight:'auto'}}>
                            Payment info :
                        </div>
                        <input 
                            type="text"
                            placeholder="Card Number"
                            style={{width:'100%',...inputStyle,marginTop:20}}
                            value={cardNumber}
                            onChange={e=>setCardNumber(nums(e.target.value.replace(/ /g, '').replace(/(.{4})/g, '$1 ').trim().substring(0,19)))}
                        />
                        <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:10}}>
                            <div style={{flex:1,marginRight:10}}>
                                <input 
                                    type="text"
                                    placeholder="MM / YY"
                                    style={{width:'100%',...inputStyle}}
                                    value={exp}
                                    onChange={e=>setExp(nums(e.target.value).replace(/ /g, '').replace(/\//g, '').replace(/(.{2})/g, '$1 / ').substring(0,7))}
                                    onKeyDown={e=>{
                                        if(e.keyCode===8){
                                            setExp('')
                                        }
                                    }}
                                />
                            </div>
                            <div style={{flex:1}}>
                                <input 
                                    type="text"
                                    placeholder="CVC"
                                    style={{width:'100%',...inputStyle}}
                                    value={cvc}
                                    onChange={e=>setCvc(nums(e.target.value.trim().substring(0,3)))}
                                />
                            </div>
                        </div>
                        <div 
                            style={{width:'95%',height:25,marginTop:20, marginBottom:20,
                                backgroundColor:lightBlue,borderRadius:3,...center,cursor:'pointer'}}
                            onClick={()=>onCheckout({
                                cardNumber,
                                firstName,
                                lastName,
                                cvc,
                                exp,
                                email,
                                address,
                                zip,
                                state,
                                total:getTotal(cart),
                                setLoading,
                                setPage,
                                note,
                                cart,
                                setShowThanks,
                                history,
                                setCart
                            })}
                        >
                            <div style={{color:'white'}}>
                                {`Pay $${getTotal(cart)}`}
                            </div>
                        </div>
                    </div>
                }
            </div> 
            <div style={{flex:1,flexDirection:'column',margin:10}}>
                {
                    Object.values(cart).map(i=><CheckoutItem i={i} key={i.id}/>)
                }
                <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                    <div style={{fontSize:11,color:'grey',margin:10}}>
                        {"Subtotal :"}
                    </div>
                    <div style={{fontSize:11,color:'grey',margin:10,marginLeft:'auto'}}>
                        {"$" + getSubtotal(cart)}
                    </div>
                </div>
                <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                    <div style={{fontSize:11,color:'grey',margin:10}}>
                        {"Shipping & Handling :" }
                    </div>
                    <div style={{fontSize:11,color:'grey',margin:10,marginLeft:'auto'}}>
                        {"$" + getShipping(cart).toFixed(2)}
                        {/* {"$" + (getSubtotal(cart) * 0.2).toFixed(2)} */}
                    </div>
                </div>
                <div style={{flex:1,borderBottom:'1px solid grey',marginLeft:10,marginRight:10}}/>
                <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                    <div style={{fontSize:11,color:'grey',margin:10}}>
                        {"Total : "}
                    </div>
                    <div style={{fontSize:11,color:'grey',marginLeft:'auto',marginTop:10}}>
                        {"USD"}
                    </div>
                    <div style={{fontSize:16,color:'black',margin:10,fontWeight:'bold'}}>
                        {"$" + (getTotal(cart))}
                    </div>
                </div>
            </div> 
        </div>
    )
}

const CheckoutItem = ({i}) => {
    const {id,quantity,item} = i
    return(
        <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
            <div style={{flex:1,display:'flex',flexDirection:'row',borderBottom:'1px solid grey',
                    margin:10,marginBottom:0,alignItems:'center'}}
            >
                <img
                    style={{width:50,height:50,margin:10,objectFit:'contain'}}
                    src={item.imageURL}
                />
                <div style={{fontSize:11}}>
                    {item.name}
                </div>
                <div style={{fontSize:11,marginLeft:'auto'}}>
                    {"$" + (parseFloat(item.price) * quantity).toFixed(2)}
                </div>
            </div>
        </div>
    )
}

const getTotal = cart => Object.values(cart)
    .map(v=>(parseFloat(v.item.price) * v.quantity) + getShipping(cart))
    // .map(v=>(parseFloat(v.item.price) * v.quantity)* 1.2)
    .reduce((p,c)=>p+c,0)
    .toFixed(2)

const getShipping = cart => {
    let total = 0
    Object.values(cart).forEach(x=>{
        if(x.item.size != undefined){
            //shirt
            total += (3 * x.quantity)
        }else{
            //home page item
            total += ((parseFloat(x.item.price) * x.quantity)* 0.2)
        }
    })
    return total

}

// Object.values(cart)
//     .filter(v=>v.item.size==undefined)
//     .map(v=>(parseFloat(v.item.price) * v.quantity)* 0.2)
//     .reduce((p,c)=>p+c,0)
//     // .toFixed(2)

const getSubtotal = cart => Object.values(cart)
    .map(v=>parseFloat(v.item.price) * v.quantity)
    .reduce((p,c)=>p+c,0)
    .toFixed(2)

const grey = "#eff0f3"
const center = {display:'flex',alignItems:'center',justifyContent:'center'}
const blue = "#21376d"
const lightBlue = "#3ba7f9"
const inputStyle = {
    height:30,padding:5,margin:0,boxSizing:'border-box'
}

// const onCheckout = () => {}
const onCheckout = async({
    cardNumber,
    firstName,
    lastName,
    cvc,
    exp,
    email,
    state,
    address,
    zip,
    total,
    setLoading,
    setPage,
    note,
    cart,
    setShowThanks,
    history,
    setCart
})=>{
    // console.log((total * 100).toFixed(0))
    setLoading(true)
    let token = await getStripeToken({
        number:cardNumber,
        exp,
        cvc,
        donation:false
    })
    // console.log("token")
    // console.log(token)
    if(token.error){
        setLoading(false)
        _alert(token.error.message)
    } else {
        let res = await onTokenPurchase({
            token:token.id,
            amount:(total * 100).toFixed(0) ,
            firstName,
            lastName,
            email,
            state,
            address,
            zip,        
            note,
            cart
        })
        if(res.err){
            // console.log(res.err)
            setLoading(false) 
            if(res.err.param=== "email"){
                setPage(0)
            }
            _alert(res.err.message)
        }else if(res.status==="SUCCESS"){
            setLoading(false) 
            _alert(`Thank you for your ${'donation'} of $${total}!`)
            // sv('home')
            setCart({})
            setShowThanks({
                show:true,
                firstName,
                lastName,
                email,
                amount:parseFloat(total),
                monthly:false
            })
            history.push('home')
        } else {
            setLoading(false)
            // setStep(2)
            _alert('Something went wrong! Please try again!')
            // console.log(res)
        }
    }                                                 
}

function validEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default withRouter(Checkout)