import React,{useState,useEffect} from 'react';

const field = {
    width:"60%",
    margin:5,
    marginLeft:0,
    height:22,
    border:'1px solid grey',
    borderRadius:3,
    padding:4
}

const label = {
    marginBottom:6,
    marginRight:'auto',
    marginLeft:"19%",
    fontSize:13,
    marginTop:20
}

const column = {display:'flex',flexDirection:'column'}
const center = {alignItems:'center',justifyContent:'center'}

const alert = {
    position:'absolute',
    zIndex:10,
    left:'25%',
    top:'50%',
    backgroundColor:'#233c7a',
    ...column,
    ...center,
    padding:50,
    borderRadius:5
}


export const ContactUs = () => {
    const [fn,setFn] = useState('')
    const [ln,setLn] = useState('')
    const [email,setEmail] = useState('')
    const [sub,setSub] = useState('')
    const [msg,setMsg] = useState('')
    const [anim,setAnim] = useState(true)
    const [loading,setLoading] = useState(false)
    const [showAlert,setShowAlert] = useState(false)
    return(
<>
<div style={{
    width:'100%',
    position:'relative',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
}}>
    {
        showAlert &&
        <div style={alert}>
            <div style={{fontWeight:'bold',color:'white',fontSize:14}}>
                Your message has been sent!
            </div>
            <div style={{color:'white',fontSize:14}}>
                Thank you for reaching out to us, we will contact you shortly.
            </div>
        </div>
    }
    <div style={{fontSize:32,fontFamily:'Roboto',margin:20,marginTop:40,textAlign:'center',color:'#18274e'}}>
        <b>Contact Us</b>
    </div>
    <div style={{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        paddingBottom:20,
        backgroundColor:'#ededed'
    }}>
        <div style={{...column,flexDirection:'row',width:'62%',marginTop:30}}>
            <div style={{...column,flex:1}}>
                <div style={{fontSize:13}}>* First Name</div>
                <input
                    type='text'
                    // placeholder='First Name'
                    onChange={e=>setFn(e.target.value)}
                    value={fn}
                    style={{...field,width:'90%'}}
                />
            </div>
            <div style={{...column,flex:1}}>
                <div style={{fontSize:13}}>Last Name</div>
                <input
                    type='text'
                    // placeholder='Last Name'
                    onChange={e=>setLn(e.target.value)}
                    value={ln}
                    style={{...field,width:'90%'}}
                />
            </div>
        </div>

        <div style={label}>* Email</div>
        <input
            type='email'
            // placeholder='Email Address'
            onChange={e=>setEmail(e.target.value)}
            value={email}
            style={{...field}}
        />
        <div style={label}>
            * How can we help you?
        </div>
        <textarea
            type='text'
            onChange={e=>setMsg(e.target.value)}
            value={msg}
            style={{...field,height:200}}
        />
        <div 
            style={{
                width:"61%",
                height:28,
                backgroundColor:'#233c7a',
                borderRadius:3,
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                fontSize:12,
                fontFamily:'Roboto',
                color:'white',
                cursor:'pointer',
                marginTop:15,
                marginBottom:30
            }}
            onClick={async()=>{
                if(!loading){
                    setLoading(true)
                    let res =  await fetch('https://us-central1-care-c7a53.cloudfunctions.net/webMessage',
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            method:"POST",
                            body:JSON.stringify({fn,ln,email,sub:'',msg})
                        })
                    let resJSON = await res.json()
                    setLoading(false)
                    if(resJSON.error){
                        alert(resJSON.error)
                        console.log(resJSON.error)
                    } else {
                        // alert('Your message was sent to the iSupport Team')
                        setShowAlert(true)
                        setTimeout(() => {
                            setShowAlert(false)
                        }, 5000);
                        setFn('')
                        setLn('')
                        setEmail('')
                        setSub('')
                        setMsg('')
                    }
                }
            }}
        >
            SEND
        </div>
    </div>
    {loading &&
        <div style={{
            width:'100%',
            height:'100%',
            position:'absolute',
            backgroundColor:'white',
            ...center,
            ...column,
            userSelect:'none',
            opacity:0.8,
            top:0,
            left:0
        }}>
            <div style={{
                transition:'opacity 0.5s',
                opacity: anim ? 1 : 0.3,
                textAlign:'center',
                ...center,
                ...column,
            }}>
                Please Wait, Your Request is Processing
            </div>
        </div>

    }

</div>
</>
)}