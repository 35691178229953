import React, { useEffect, useState, useRef } from 'react';
import {useSpring} from 'react-spring'
import {FadePhoto} from '../components/fadePhoto.js'
import {CommentPage} from '../components/comment.js'
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import {Store} from '../components/supportATroop.js'
import {Link} from 'react-router-dom'
import carousel01 from '../images/home_slides_april2021/carousel01.png'
import carousel02 from '../images/home_slides_april2021/carousel02.png'
import carousel03 from '../images/home_slides_april2021/carousel03.png'
import carousel04 from '../images/home_slides_april2021/carousel04.png'
import map from '../images/map_sm.png'
import logo1 from '../images/logos/1.jpg'
import logo2 from '../images/logos/2.png'
import logo3 from '../images/logos/3.jpg'
import logo4 from '../images/logos/4.jpg'

const fades = [
    {a:1,b:0,c:0,d:0,e:0,f:1,g:0,h:0},
    {a:0,b:1,c:0,d:0,e:0,f:1,g:0,h:0},
    {a:0,b:0,c:1,d:0,e:1,f:0,g:0,h:0},
    {a:0,b:0,c:0,d:1,e:1,f:0,g:0,h:0},
    {a:1,b:0,c:0,d:0,e:0,f:1,g:0,h:0},
    {a:0,b:1,c:0,d:0,e:0,f:1,g:0,h:0},
    {a:0,b:0,c:1,d:0,e:1,f:0,g:0,h:0},
    {a:0,b:0,c:0,d:1,e:1,f:0,g:0,h:0},
]

export const Home = ({items,useCart,sv}) => {
    const anim = useRef(null)
    const [x, setX] = useState(0)
    const {width} = useWindowDimensions()
    const mobile = width < 420
    const [props, set] = useSpring(()=>({from: {a:1,b:0,c:0,d:0,e:1,f:0,g:0,h:0}, config: {friction: 60}}))
    const [linkInImage,setLinkInImage] = useState(false)
    const [numOrders,setNumOrders] = useState(11081)
    useEffect(()=>{
        let i = setInterval(()=>{
            set(fades[x<7 ? x+1 : 0])
            setLinkInImage(x==1 || x==2 || x==5 || x==6)
            console.log(x)
            setX(x<7 ? x+1 : 0)
        // },2000)
        },6000)
        return(()=>{
            clearInterval(i)
        })
    })
    useEffect(()=>{
        fetch('/numItems',
        // fetch('https://us-central1-care-c7a53.cloudfunctions.net/numItems',
            {
                headers: {'Content-Type': 'application/json'},
                method: "GET",
            })
        .then(res=>res.json())
        .then(json=>{
            console.log(json)
            setNumOrders(json.numItems)
        })
    },[])

    return(
<div>

{/* BIG IMAGE THING */}

    <div 
        style={{
            width:'100%',
            position:'relative',
            overflow:'hidden',
            backgroundColor:'white',
            zIndex:1
        }}
        ref={anim}
    >
    {/* PLACEHOLDER TO MAKE SPACE FOR ABSOLUTE POSITIONED IMAGES */}
        <img style={{width:'100%',objectFit:'contain',opacity:0}} src={carousel01} alt={'place holder'}/>
    {/* THE ACTUAL PHOTOS */}
        <FadePhoto op={props.a} image={carousel01} alt='image1'/>
        <FadePhoto op={props.b} image={carousel02} alt='image2'/>
        <FadePhoto op={props.c} image={carousel03} alt='image3'/>
        <FadePhoto op={props.d} image={carousel04} alt='image4'/>
        {(x==2 || x==6) &&
            <Link to="mobile-app">
                <div
                    style={{
                        position:'absolute',
                        left:'20%',
                        right:'60%',
                        top:'65%',
                        bottom:'15%',
                        zIndex:100,
                        cursor:'pointer',
                        // border:'1px solid red'
                    }}
                />
            </Link>
        }
        {(x==3 || x==7) &&
            <Link to="store">
                <div
                    style={{
                        position:'absolute',
                        left:'60%',
                        right:'20%',
                        top:'65%',
                        bottom:'15%',
                        zIndex:100,
                        cursor:'pointer',
                        // border:'1px solid red'
                    }}
                />
            </Link>
        }
    </div>

    {/* MESSAGES FROM .... */}

    <div style={{
        width:'100%',
        height:80,
        fontSize: mobile ? 24 : 40,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        backgroundColor:'white',
        fontFamily:'Roboto',
        color:"#18274e"
    }}>
        REVIEWS AND MESSAGES
    </div>

    {/* MESSAGES */}

    <div style={{
        width:'100%',
        height:500,
        position:'relative',
        backgroundColor:"#dbdbdb"
    }}>
        <CommentPage op={props.e} all commentNumbers={[0,1,2,3,4,5]}/>
        <CommentPage op={props.f} all commentNumbers={[6,7,8,9,10,11]}/>
        {/* <CommentPage op={props.g} all commentNumbers={[0,1,2,3,4,5]}/>
        <CommentPage op={props.h} all commentNumbers={[6,7,8,9,10,11]}/> */}
        {/* <CommentPage op={props.g} all commentNumbers={[13,14,15,16,17,18]}/>
        <CommentPage op={props.h} all commentNumbers={[19,20,21,22,23,24]}/> */}
    </div>

    {/* STORE */}
    {/* <Store items={items} useCart={useCart}/> */}

    {/* MAP */}

    <div style={{...center,flexDirection:'row', marginBottom:30,marginTop:50,flexWrap:'wrap'}}>
        <div style={{
            fontSize: mobile ? 20 : 30,
            backgroundColor:'white',
            fontFamily:'Roboto',
            color:"#18274e",
            margin:5
        }}>
            YOUR SUPPORT HAS HELPED SEND
        </div>
        <div style={{
            fontSize: mobile ? 24 : 34,
            backgroundColor:'white',
            fontFamily:'Roboto',
            color:"#b61d24",
            margin:5,
            fontWeight:'bold'
        }}>
            {`${numOrders} ITEMS`}
            {/* {`${numOrders} CARE PACKAGES`} */}
        </div>
    </div>
    <img 
        style={{
            width:'100%',
            objectFit:'contain',
        }} 
        src={map} 
        alt={'map of world'}
    />
    <div style={{fontWeight:'bold',color:"#18274e",fontFamily:'Roboto',marginLeft:20,fontSize:11}}>
        (THIS IS NOT AN ACCURATE DEPICTION OF WHERE OUR TROOPS ARE, AS OPSEC RULES ARE IMPORTANT TO US)
    </div>

    {/* FEATURED */}

    <div style={{
        fontSize: mobile ? 20 : 30,
        backgroundColor:'white',
        fontFamily:'Roboto',
        color:"#18274e",
        margin:5,
        marginTop:40,
        marginBottom:20,
        width:'100%',
        ...center
    }}>
        FEATURED ON
    </div>
    <Link to="features" style={{textDecoration:'none'}}>
        <div 
            style={{width:'100%',...center,cursor:'pointer'}}
            onClick={()=>sv('features')}    
        >
            <img 
                style={{width:'20%',objectFit:'contain',margin:mobile?'2%':20}} 
                src={logo1} 
                alt={'map of world'}
            />
            <img 
                style={{width:'20%',objectFit:'contain',margin:mobile?'2%':20}}
                src={logo2} 
                alt={'map of world'}
            />
            <img 
                style={{width:'20%',objectFit:'contain',margin:mobile?'2%':20}} 
                src={logo3} 
                alt={'map of world'}
            />
            <img 
                style={{width:'20%',objectFit:'contain',margin:mobile?'2%':20}} 
                src={logo4} 
                alt={'map of world'}
            />
        </div>
    </Link>
</div>
)}

const center = {display:'flex',alignItems:'center',justifyContent:'center'}
