export const colors = {
    darkBlue: '#284375',
    royalBlue:'#6875D8',
    medBlue:'#3CA7F4',
    lightBlue:'#f2f7ff',
    dBlue:'#062c69',
    gray:'#d3d9e2',
    lightGray:'#f0f0f0',
    medGray:'#A0A0A0',
    orange:'#e87c29'
}