import React, {useState} from 'react';
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import SAT_logo from '../images/logos/SupportaTroop_Logo_WhiteTxt.png'
import socials_fb from '../images/logos/SocialMedia_fb.png'
import socials_ig from '../images/logos/SocialMedia_ig.png'
import socials_tw from '../images/logos/SocialMedia_tw.png'
import socials_fb_m from '../images/logos/SocialMedia_fb_m.png'
import socials_ig_m from '../images/logos/SocialMedia_ig_m.png'
import socials_tw_m from '../images/logos/SocialMedia_tw_m.png'
import AppStore from '../images/logos/AppStore.png'
import GooglePlay from '../images/logos/GooglePlay.png'
import {Link as _Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar} from "@fortawesome/free-solid-svg-icons";

const Link = x =>  <_Link to={x.to} style={{ textDecoration: 'none' }}>{x.children}</_Link>

export const Footer = ({sv,v}) => {
    const {width} = useWindowDimensions()
    const mobile = width < 520
    const [email,setEmail] = useState("")
    return(
<>
<div
    style={{
        width:'100%',
        height:'100%',
        display:'flex',
        flexDirection:'column'
    }}
>
    {/* GREY PART */}
    <div style={{...column,backgroundColor:'lightGrey',paddingBottom:30}}>
        <Stars/>
        <div style={!mobile?{...row,marginTop:10}:column}>
            <div style={{...column,...center, padding:20}}>
                <div style={{...row,...center}}>
                    <div style={{...text, fontSize:18,marginRight:5}}>
                        SIGN UP FOR
                    </div>
                    <div style={{...text, color:"#b61d24", fontSize:24}}>
                        R.E.D. FRIDAY
                    </div>
                </div>
                <div style={{...row,marginTop:20}}>
                    <input 
                        type="text"
                        style={{...input}}
                        onChange={e=>setEmail(e.target.value)}
                        value={email}
                    />
                    <div 
                        style={{...button,borderTopLeftRadius:0,borderBottomLeftRadius:0,cursor:"pointer"}}
                        onClick={()=>{
                            submitEmail(email)
                            setEmail("")
                        }}
                    >
                        SUBMIT
                    </div>
                </div>
            </div>
            {mobile && <Stars/> }
            <div style={{...column, ...center, paddingLeft:60,paddingRight:60}}>
                <div style={{...row, ...center, width:'100%',marginBottom:20}}>
                    <div style={{...text,...center, fontSize:18, marginRight:10}}>
                        LEARN ABOUT OUR
                    </div>
                    <Link to="mobile-app">
                        <div style={{...button}}>
                            MOBILE APP
                        </div>
                    </Link>
                </div>
                <div style={{...row}}>
                    <div style={{...column,paddingRight:25}}>
                        <div style={{...text,fontWeight:'bold',fontSize:12}}>
                            Patriot?
                        </div>
                        <div style={{...text,fontSize:11,maxWidth:150,textAlign:'center'}}>
                            Download our app to help hundreds of deployed service members receive items off of their registry.
                        </div>
                    </div>
                    <div style={{...column}}>
                        <div style={{...text,fontWeight:'bold',fontSize:12}}>
                            Deployed Service Member?
                        </div>
                        <div style={{...text,fontSize:11,maxWidth:150}}>
                            Use our app to be connected with hundreds of patiots who want to help you on your deployment.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    {/* BLUE PART */}

    {!mobile &&

        <div style={{...column,backgroundColor:'#18274e',paddingTop:30,paddingBottom:10}}>
            <div style={{...row}}>
                {/* LOGO */}
                <div style={{...column,flex:2}}>
                    <img 
                        style={{width:'100%',objectFit:'contain'}}
                        src={SAT_logo} 
                        alt="Support A Troop Logo"
                    />
                </div>
                {/* LINKS */}
                <div style={{...row,flex:3,paddingLeft:15,paddingRight:15}}>
                    <div style={{...column,justifyContent:'space-around'}}>
                        <Link to="home">
                            <div style={{...text,color:'white',fontSize:13}}>
                                Home
                            </div>
                        </Link>
                        <Link to="about">
                            <div style={{...text,color:'white',fontSize:13}}>
                                About
                            </div>
                        </Link>
                        <Link to="mobile-app">
                            <div style={{...text,color:'white',fontSize:13}}>
                                Mobile App
                            </div>
                        </Link>
                    </div>
                    <div style={{...column,justifyContent:'space-around'}}>
                        <Link to="ways-to-support">
                            <div style={{...text,color:'white',fontSize:13}}>
                                Ways To Support
                            </div>
                        </Link>
                        {/* <Link to="store">
                            <div style={{...text,color:'white',fontSize:13}}>
                                Store
                            </div>
                        </Link> */}
                        <Link to="contact-us">
                            <div style={{...text,color:'white',fontSize:13}}>
                                Contact
                            </div>
                        </Link>
                    </div>
                </div>

                {/* SOCIALS */}
                <div style={{...column,flex:2, ...center}}>
                    <div style={{...text, color:'white', fontSize:16}}>
                        FOLLOW US
                    </div>
                    <div style={{...row,width:'100%',justifyContent:'center',alignItems:'center'}}>
                        <img 
                            style={{width:'30%',objectFit:'contain',cursor:'pointer'}}
                            src={socials_fb} 
                            alt="facebook logo"
                            onClick={()=>window.open("https://www.facebook.com/supportatroop", "_blank")}
                        />
                        <img 
                            style={{width:'26%',objectFit:'contain',cursor:'pointer'}}
                            src={socials_ig} 
                            alt="instagram logo"
                            onClick={()=>window.open("https://www.instagram.com/supportatroop/?hl=en", "_blank")}
                        />
                        <img 
                            style={{width:'33%',objectFit:'contain',cursor:'pointer'}}
                            src={socials_tw} 
                            alt="twitter logo"
                            onClick={()=>window.open("https://twitter.com/supportatroop?lang=en", "_blank")}
                        />
                    </div>
                </div>
                {/* APP LINKS */}
                <div style={{...column,flex:3,...center}}>
                    <div style={{...text,color:'white',fontSize:16,marginBottom:5}}>
                        Our App
                    </div>
                    <div style={{...text,color:'white',fontSize:12}}>
                        Our app is available on both
                    </div>
                    <div style={{...text,color:'white',fontSize:12}}>
                        the App Store and Google Play
                    </div>
                    <div style={{...row}}>
                        <div style={{...row,...center,}}>
                            <img 
                                style={{width:'80%',objectFit:'contain',cursor:'pointer'}}
                                src={AppStore} 
                                alt="App Store Logo"
                                onClick={()=>window.open("https://apps.apple.com/ca/app/isupport-care-package-builder/id1495734737", "_blank")}
                            />
                        </div>
                        <div style={{...row,...center}}>
                            <img 
                                style={{width:'80%',objectFit:'contain',cursor:'pointer'}}
                                src={GooglePlay} 
                                alt="Google Play Logo" 
                                onClick={()=>window.open("http://play.google.com/store/apps/details?id=com.iSupportDeployedTroops.iSupportCarePackageBuilder", "_blank")}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* FOOTER */}
            <div style={row}>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <div style={{...text,color:'white',fontSize:10,marginLeft:10,marginBottom:3}}>
                        © 2023 Support A Troop. All Rights Reserved
                    </div>
                    <div style={{...text,color:'white',fontSize:10,opacity:0.5,marginLeft:10}}>
                        The appearance of U.S. Department of Defence (DOD) visual information does not imply or constitue DOD endorsement.
                    </div>
                </div>
                <Link to="/termsAndConditions">
                    <div style={{...text,cursor:'pointer',color:'white',fontSize:11,margin:10,marginLeft:20}}>
                        Terms and Conditions
                    </div>
                </Link>
                <Link to="/privacy">
                    <div style={{...text,cursor:'pointer',color:'white',fontSize:11,margin:10}}>
                        Privacy Policy
                    </div>
                </Link>

            </div>
        </div>
    }
    {mobile &&

        <div style={{...column,backgroundColor:'#18274e',paddingTop:10,paddingBottom:10}}>
            <div style={{...row}}>
                {/* LOGO */}
                <div style={{...column,flex:1}}>
                    <img 
                        style={{width:'100%',objectFit:'contain'}}
                        src={SAT_logo} 
                        alt="Support A Troop Logo"
                    />
                </div>
                {/* LINKS */}
                <div style={{...row,flex:1}}>
                    <div style={{...column,justifyContent:'space-around'}}>
                        <Link to="home">
                            <div style={{...text,color:'white',fontSize:10}}>
                                Home
                            </div>
                        </Link>
                        <Link to="about">
                            <div style={{...text,color:'white',fontSize:10}}>
                                About
                            </div>
                        </Link>
                        <Link to="mobile-app">
                            <div style={{...text,color:'white',fontSize:10}}>
                                Mobile App
                            </div>
                        </Link>
                    </div>
                    <div style={{...column,justifyContent:'space-around'}}>
                        <Link to="ways-to-support">
                            <div style={{...text,color:'white',fontSize:10}}>
                                Ways To Support
                            </div>
                        </Link>
                        {/* <Link to="home">
                            <div style={{...text,color:'white',fontSize:10}}>
                                Store
                            </div>
                        </Link> */}
                        <Link to="contact-us">
                            <div style={{...text,color:'white',fontSize:10}}>
                                Contact
                            </div>
                        </Link>
                    </div>
                </div>

                {/* SOCIALS ETC */}
                <div style={{...column,flex:1, ...center}}>
                    <div style={{...text, color:'white', fontSize:12,margin:3}}>
                        FOLLOW US
                    </div>
                    <div style={{...row,width:'100%',justifyContent:'center',alignItems:'center'}}>
                        <img 
                            style={{width:'30%',objectFit:'contain',cursor:'pointer'}}
                            src={socials_fb_m} 
                            alt="facebook logo"
                            onClick={()=>window.open("https://www.facebook.com/supportatroop", "_blank")}
                        />
                        <img 
                            style={{width:'26%',objectFit:'contain',cursor:'pointer'}}
                            src={socials_ig_m} 
                            alt="instagram logo"
                            onClick={()=>window.open("https://www.instagram.com/supportatroop/?hl=en", "_blank")}
                        />
                        <img 
                            style={{width:'33%',objectFit:'contain',cursor:'pointer'}}
                            src={socials_tw_m} 
                            alt="twitter logo"
                            onClick={()=>window.open("https://twitter.com/supportatroop?lang=en", "_blank")}
                        />
                    </div>
                {/* </div> */}
                {/* APP LINKS */}
                {/* <div style={{...column,flex:3,...center}}> */}
                    <div style={{...text,color:'white',fontSize:12,margin:3}}>
                        Our App
                    </div>
                    <div style={{...text,color:'white',fontSize:10,textAlign:'center'}}>
                        Our app is available on both
                    </div>
                    <div style={{...text,color:'white',fontSize:10,textAlign:'center'}}>
                        the App Store and Google Play
                    </div>
                </div>
            </div>
            <div style={{width:'100%',display:'flex',flexDirection:"column"}}>
                <div style={{display:'flex',flexDirection:'row', marginLeft:'auto'}}>
                    <div style={{...row,...center,marginTop:5,marginBottom:5}}>
                        <img 
                            style={{width:100,objectFit:'contain',cursor:'pointer',margin:3}}
                            src={AppStore} 
                            alt="App Store Logo"
                            onClick={()=>window.open("https://apps.apple.com/ca/app/isupport-care-package-builder/id1495734737", "_blank")}
                        />
                    </div>
                    <div style={{...row,...center}}>
                        <img 
                            style={{width:100,objectFit:'contain',cursor:'pointer',margin:3}}
                            src={GooglePlay} 
                            alt="Google Play Logo" 
                            onClick={()=>window.open("http://play.google.com/store/apps/details?id=com.iSupportDeployedTroops.iSupportCarePackageBuilder", "_blank")}
                        />
                    </div>
                </div>
            </div>
            {/* FOOTER */}
            <div style={column}>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <div style={{...text,color:'white',fontSize:10,marginLeft:10,marginBottom:3}}>
                        © 2021 Support A Troop. All Rights Reserved
                    </div>
                    <div style={{...text,color:'white',fontSize:10,opacity:0.5,marginLeft:10}}>
                        The appearance of DOD information does not imply or constitue DOD endorsement.
                    </div>
                </div>
                <Link to="/termsAndConditions">
                    <div style={{...text,cursor:'pointer',color:'white',fontSize:11,margin:10}}>
                        Terms and Conditions
                    </div>
                </Link>
                <Link to="/privacy">
                    <div style={{...text,cursor:'pointer',color:'white',fontSize:11,margin:10,marginTop:0}}>
                        Privacy Policy
                    </div>
                </Link>
            </div>
        </div>
    }

</div>
</>
)}

const row = {
    flex:1,
    display:'flex',
    flexDirection:'row',
}
const column = {
    flex:1,
    display:'flex',
    flexDirection:'column',
}

const center = {
    alignItems :'center',
    justifyContent:'center'
}

const text = {
    color:"#18274e",
    fontFamily:'Roboto',
}

const input = {
    border:'1px solid #18274e',
    height:26,
    borderRadius: 5,
    borderTopRightRadius:0,
    borderBottomRightRadius:0,
    marginRight:2,
    outline:'none'
}

const button = {
    width:100,
    height:30,
    backgroundColor:'#233c7a',
    color:'white',
    display:'flex',
    borderRadius:3,
    fontSize:12,
    ...center
}

const Stars = () => {
    const {width} = useWindowDimensions()
    const mobile = width < 520
    return(
        <div
            style={{
                width:'100%',
                flexDirection:'row',
                display:'flex',
                alignItems:'center',
                justifyContent:'space-around',
                paddingTop:10,
                paddingBottom:10,
            }}
        >
            {
                Array(mobile?12:24).fill().map((x,i)=>
                    <FontAwesomeIcon key={i} icon={faStar} style={{opacity:'0.5'}} color="grey"/>
                )
            }
        </div>
    )}

const submitEmail = async email => {
    console.log(email)
    const res = await fetch('https://us-central1-care-c7a53.cloudfunctions.net/redFridayEmailAdd',
        {
            headers: {'Content-Type': 'application/json'},
            method:"POST",
            body:JSON.stringify({email:email})
        })
    const json = await res.json()
    console.log(json)
    if(json.success){
        window.alert("Thank you for submitting your email address.")
    } else {
        window.alert("Something went wrong, your email address was not submitted.")
    }
}