import React, {useState} from 'react';
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft  } from "@fortawesome/free-solid-svg-icons";
import {animated,useSpring} from 'react-spring'

import banner from '../images/WaystoSupport_Banner.png'
import socials from '../images/ways/socials.png'

import i1 from '../images/ways/1b.png'
import i2 from '../images/ways/2.jpg'
import i3 from '../images/ways/3.png'
import i4 from '../images/ways/4.png'


let wordsStyle = {
    fontFamily:'Roboto-medium',
    fontSize:24,
    // textAlign:'center',
    margin:20,
    // color:'#636363'
}

let sectionStyle = {
    width:'100%',
    display:'flex',
    flexDirection:'row',
    marginTop:20
}
let sectionStyleMobile = {
    width:'100%',
    display:'flex',
    flexDirection:'column',
    marginTop:20
}
let sectionStyleMobile_reverse = {
    width:'100%',
    display:'flex',
    flexDirection:'column-reverse',
    marginTop:20
}

let halfSectionStyle = {
    flex:1,
    padding:25
}

let blueTextStyle = {
    fontSize: 26,
    color: '#18274e',
    fontFamily:'Roboto',
    marginBottom:20,
    fontWeight:'bold'
}

let normalTextStyle = {
    fontSize: 22,
    // color: '#545353',
    fontFamily:'Roboto-meduim',
    whiteSpace:'pre-wrap',
}

const fades = [
    {a:1,b:0,c:0,d:0,e:0,f:0},
    {a:0,b:1,c:0,d:0,e:0,f:0},
    {a:0,b:0,c:1,d:0,e:0,f:0},
    {a:0,b:0,c:0,d:1,e:0,f:0},
    {a:0,b:0,c:0,d:0,e:1,f:0},
    {a:0,b:0,c:0,d:0,e:0,f:1},
]

const fadeIndex=['a','b','c','d','e','f']

export const WaysToSupport = () => {
    const {width} = useWindowDimensions()
    const mobile = width < 520
    const [viewing,setViewing] = useState(0)
    const [props, set] = useSpring(()=>({from: {a:1,b:0,c:0,d:0,e:0,f:0}, config: {friction: 60}}))
    return(     
<div style={{display:'flex',flexDirection:'column',marginBottom:100}}
>
    <img 
        style={{
            width:'100%',
            objectFit:'contain',
        }}
        src={banner} 
        alt={'banner'}
    />
    <div style={{height:30}}/>
    <div style={{...wordsStyle,paddingLeft:40,paddingRight:40,textAlign:'center'}}>
        If you have already downloaded the app but you still would like to further your support of our mission, in bridging the gap between patriots and deployed troops; the following are easy and effective ways you can help!
    </div>

    <div style={mobile?sectionStyleMobile:sectionStyle}>
        <div style={halfSectionStyle}>
            <div style={blueTextStyle}>
                1. Follow, Share, and Like us on Social Media!
            </div>
            <div style={normalTextStyle}>
                {
                    "Any exposure does a lot for our mission!"
                    // "\n60% of our donations we receive are from the generous people who have seen our posts on social media; which was shared by family and friends."
                }
            </div>
            <div style={{...normalTextStyle}}>
                {"  - Like us on Facebook"}
            </div>
            <div style={{...normalTextStyle}}>
                {"  - Follow us on Instagram, and Twitter"}
            </div>
            <div style={{...normalTextStyle,fontFamily:'Roboto-bold'}}>
                Don’t be afraid to interact with our posts! We created it for patriots like you!
            </div>
        </div>
        <div style={{...halfSectionStyle,position:'relative'}}>
            <img 
                style={{width:'100%',objectFit:'contain',}}
                src={i1} 
                alt={'solier'}
            />
            <div
                style={{
                    position:'absolute',
                    right:20,
                    top:20,
                    width:200
                }}
            >
                <img 
                    style={{width:'100%',objectFit:'contain',}}
                    src={socials} 
                    alt={'socials'}
                />
            </div>
        </div>
    </div>
    <div style={mobile?sectionStyleMobile_reverse:sectionStyle}>
        <div style={halfSectionStyle}>
            <img 
                style={{width:'100%',objectFit:'contain',}}
                src={i2} 
                alt={'call a friend'}
            />
        </div>
        <div style={halfSectionStyle}>
            <div style={blueTextStyle}>
                2. Bring Us Up in Conversation
            </div>
            <div style={normalTextStyle}>
                {
                    'Just simply talk about us to a friend!'+
                    '\n\nWord of mouth is a fast, easy and effective way to help us spread our mission!'
                }
            </div>

        </div>
    </div>
    {/* <div style={mobile?sectionStyleMobile:sectionStyle}>
        <div style={halfSectionStyle}>
            <div style={blueTextStyle}>
                3. Be a $1 Member Donor
            </div>
            <div style={normalTextStyle}>
                {
                    'A little goes a long way !\n'+
                    '  - Download and make a profile on the Support A Troop mobile app, and sign up to become a monthly donor to receive a Monthly Donor Medallion!'+
                    '\n  - All monthly donation options go directly to our non-profit organization, iSupport Deployed Troops, Inc.' +
                    '\n  - Small donations make a huge difference to our deployed service members.'
                }
            </div>
        </div>
        <div style={halfSectionStyle}>
            <img 
                style={{width:'100%',objectFit:'contain',}}
                src={i3} 
                alt={'gold badge'}
            />
        </div>
    </div> */}
    <div style={mobile?sectionStyleMobile:sectionStyle}>
        {/* <div style={halfSectionStyle}>
            <img 
                style={{width:'100%',objectFit:'contain',}}
                src={i4} 
                alt={'gold badge'}
            />
        </div> */}
        <div style={halfSectionStyle}>
            <div style={blueTextStyle}>
                3. Download our mobile app!
            </div>
            <div style={normalTextStyle}>
                {
                    'The best way to show your support is to download our mobile app, Support A Troop, available on both the App Store and Google Play.'
                    + '\nFrom the Patriotic side, you will be able to choose from hundreds of soldiers and send them items directly off of their registry!'
                }
            </div>
        </div>
        <div style={halfSectionStyle}>
            <img 
                style={{width:'100%',objectFit:'contain',}}
                src={i4} 
                alt={'gold badge'}
            />
        </div>

    </div>
</div>
)}

const S = ({img,alt,linkTo}) =>
    <div style={{
        width:28,
        height:28,
        borderRadius:24,
        padding:4,
        opacity:0.8,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:'grey'
    }}>
    
        <img 
            style={{
                width:24,
                objectFit:'contain',
                cursor:'pointer',
            }}
            src={img} 
            alt={alt}
            onClick={()=>{window.open(linkTo, "_blank")}}
        />
    </div>
