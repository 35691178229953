import {useLocation} from "react-router-dom";

const CheckForAppDonation = ({setAppDonation}) => {
  const location = useLocation()
  if(location.pathname=="/payment-portal"){
    const query = new URLSearchParams(location.search)
    const uid = query.get('uid')
    const amount = query.get('amount')
    const tier = query.get('tier')
    const name = query.get('name')
    const email = query.get('email')
    const isDonor = query.get('isDonor') == "true"
    setAppDonation({uid,amount,tier,name,email,isDonor})
  }
  return null
}

export default CheckForAppDonation