import React from 'react';
import {animated} from 'react-spring'

export const FadePhoto = ({op,image,alt,minWidth}) =>
    <animated.div style={{
        // zIndex:1,
        width:'100%',
        height:'100%',
        position:'absolute',
        top:0,
        left:0,
        opacity:op,
        minWidth: minWidth ? minWidth : 0,
        zIndex:1
    }}>
        <img 
            style={{
                width:'100%',
                objectFit:'contain',
                zIndex:1
            }}
            src={image} 
            alt={alt} 
        />
    </animated.div>
