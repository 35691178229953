import React, {useState} from 'react';
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faStar  } from "@fortawesome/free-solid-svg-icons";
import {animated,useSpring} from 'react-spring'
import {Link} from "react-router-dom";

import banner from '../images/banners/MobileApp_Banner.png'

import i1 from '../images/phoneMocksPatriot/1.png'
import i2 from '../images/phoneMocksPatriot/2.png'
import i3 from '../images/phoneMocksPatriot/3.png'
import i4 from '../images/phoneMocksPatriot/4.png'

import ios_store from '../images/ios_store.jpg';
import android_store from '../images/android_store.jpg';

const gray = "#828384"
const blue = "#18274e"
const red = "#b62d24"

const fadeIndex=['a','b','c']
const fades = [
    {a:1,b:0,c:0},
    {a:0,b:1,c:0},
    {a:0,b:0,c:1},
]

export const MobileApp = ({sv}) => {
    const {width} = useWindowDimensions()
    const [viewing,setViewing] = useState(0)
    const mobile = width < 520
    const [props, set] = useSpring(()=>({from: {a:1,b:0,c:0}, config: {friction: 60}}))

    return(
<div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>

    {/* BANNER` */}
    <div style={{width:'100%',position:'relative'}}>
        <img 
            style={{
                width:'100%',
                objectFit:'contain',
            }}
            src={banner} 
            alt={'banner'}
        />
        <div 
            style={{position:"absolute",top:'55%',left:"28%",height:'27%',width:'21%', cursor:'pointer',zIndex:10}}
            onClick={()=>window.open("https://apps.apple.com/ca/app/isupport-care-package-builder/id1495734737", "_blank")}
        />
        <div 
            style={{position:"absolute",top:'55%',left:"49%",height:'27%',width:'24%', cursor:'pointer',zIndex:10}}
            onClick={()=>window.open("http://play.google.com/store/apps/details?id=com.iSupportDeployedTroops.iSupportCarePackageBuilder", "_blank")}
        />
    </div>

    {/* TITLE */}

        <div style={{
            fontFamily:'Roboto',
            fontSize:mobile ? 40 : width < 600 ? 32 : 40,
            marginLeft:50,
            color:red,
            marginRight:'auto',
            marginTop:40
        }}>
            <b>For the patriot</b>
        </div>
        <div style={{margin:30,marginBottom:0}}>
        <div style={title}>
            ABOUT
        </div>
        <div style={text}>
            The Support A Troop app is a mobile military care package app. You can send a care package without ever having to leave the comfort of your home.        </div>
        <div style={text}>
            The Support A Troop app allows you to see troops overseas, view their wish list of items they are in need of, and send them items off of their wish list. (All of which is done through the app) You will never have to package or ship items through the post office, as we handle all of that!
        </div>
        <div style={text}>
            Once a care package has been received, the solider has the option to send you a photo and a thank you message, thanking you for your support, which you can share to your social media sites! 
        </div>

        <div style={title}>
            WHY DOWNLOAD OUR APP?
        </div>
        <div style={text}>
            The Support A Troop app is the future of care package delivery. There is no quicker, easier, cheaper and more satisfying way to directly help a troop overseas. We offer low cost shipping on all orders, and for only $5 you can send a care package to a troop.
        </div>
    </div>

    {/* 3 SCREEEN GRABS */}

    <div style={{
        width:'100%',
        display:'flex',
        justifyContent:'center',
        position:'relative',
        cursor:'pointer',
    }}>
        <div 
            style={{
                cursor:'pointer',
                width:30,
                zIndex:1,
                flexGrow:10,
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
            }}
            onClick={()=>{
                if(viewing>0){
                    set(fades[viewing-1])
                    setViewing(viewing-1)
                }
            }}   
        >
            {viewing>0 &&
                <FontAwesomeIcon 
                    icon={faChevronCircleLeft} 
                    size="2x"
                    color='#18274e'
                />
            }
        </div>

    {/* DUMMY */}

        {
            [i1,i2,i3].map((photo,i)=>
                <div key={i} style={{
                    width:'30%',
                    margin:2,
                    borderRadius:mobile ? 10 : 30,
                    overflow:'hidden',
                    opacity:0
                }}>
                    <img 
                        style={{
                            width:'100%',
                            objectFit:'contain',
                        }}
                        src={photo} 
                        alt={'ty1'}
                    />
                </div>
        )}

    {/* REAL ONES */}

        {
            [[i1,i2,i3],[i2,i3,i4]]
            .map((x,j)=>
                <div key={j} style={{
                    display:'flex',
                    flexDirection:'row',
                    position:'absolute',
                    left:0,
                    top:0,
                }}>
                    <div style={{width:40}}/>
                    {
                        x.map((photo,i)=>
                            <animated.div key={i} style={{
                                width:'30%',
                                margin:2,
                                borderRadius:mobile ? 10 : 20,
                                overflow:'hidden',
                                opacity:props[fadeIndex[j]]
                            }}>
                                <img 
                                    style={{
                                        width:'100%',
                                        objectFit:'contain',
                                    }}
                                    src={photo} 
                                    alt={'ty1'}
                                />
                            </animated.div>
                    )}
                    <div style={{width:40}}/>
                </div>
            )
        }
        <div 
            style={{
                cursor:'pointer',
                width:30,
                zIndex:1,
                flexGrow:10,
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
            }}
            onClick={()=>{
                if(viewing<1){
                    set(fades[viewing+1])
                    setViewing(viewing+1)
                }
            }}
        >
            {viewing<1 &&
                <FontAwesomeIcon 
                    icon={faChevronCircleRight} 
                    size="2x"
                    color='#18274e'
                />
            }
        </div>
    </div>

    {/* FOOTER */}
    <div 
        style={{
            width:'90%',
            backgroundColor:red,
            borderRadius:10,
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            marginTop:12
        }}
    >
        {!mobile &&
            <Stars/>
        }
        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <div style={{color:'white',fontSize:13,textAlign:'center',marginLeft:10,marginRight:10}}>
                If you are a <b>U.S. SERVICE MEMBER</b>
                
            </div>
            <div style={{color:'white',fontSize:13,textAlign:'center',marginLeft:10,marginRight:10}}>
                looking to create a care package profile
            </div>
        </div>
        <Link to="mobile-app-troop" style={{textDecoration:'none'}}>
            <div 
                style={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    justifyContent:'center',
                    backgroundColor:"#233c7a",
                    color:'white',
                    borderRadius:6,
                    padding:15,
                    paddingTop:10,
                    paddingBottom:10,
                    margin:10,
                    fontSize:12,
                    textAlign:'center',
                    cursor:'pointer'
                }}
                onClick={()=>sv('mobile-app-troop')}
            >
                CLICK HERE
            </div>
        </Link>
        {!mobile &&
            <Stars/>
        }
    </div>

    <div style={{height:100}}/>
</div>
)}

const Stars = ({left,nine}) => 
    <div
        style={{
            flex:1,
            flexDirection:'row',
            display:'flex',
            alignItems:'center',
            justifyContent:'space-around',
            paddingLeft:20,
            paddingRight:20,
            fontSize:30
        }}
    >
        {
            Array(4).fill().map((x,i)=>
                <FontAwesomeIcon key={i} icon={faStar} style={{opacity:'0.5'}} size="xs" color="white"/>
            )
        }
    </div>

const title = {
    fontSize:20,
    color:blue,
    fontFamily:'Roboto-medium',
    margin:20,
    marginTop:30
}
const text = {
    fontFamily:'Roboto-medium',
    margin:20,
    marginBottom:0,
    fontSize:14
}
