import React, {useState, useEffect} from 'react'
import useWindowDimensions from '../helpers/useWindowDimensions.js'
import SATLogo from '../images/iSupportLogo.png'
// import SATLogo from '../images/satlogonotext.png'
import stripeBadge from "../images/stripeBadge.png"
import {getStripeToken, onTokenAppDonation} from '../helpers/getStripeToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard  } from "@fortawesome/free-solid-svg-icons";
import { faCalendar  } from "@fortawesome/free-solid-svg-icons";
import { faLock  } from "@fortawesome/free-solid-svg-icons";


const nums = x => Array.from(x).filter(c=>c===' ' || (c>="0" && c<="9")).join('')
const _alert = x => setTimeout(()=>alert(x),200)
const center = {display:'flex',alignItems:'center',justifyContent:'center'}
const lightBlue = "#3ba7f9"
const inputStyle = {padding:5,margin:0,boxSizing:'border-box',borderRadius:0,borderWidth:0}

const AppDonationPortal = ({setShowThanks,appDonation,sv}) => {
    const {width,height} = useWindowDimensions()
    const [cardNumber, setCardNumber] = useState()
    const [cvc, setCvc] = useState()
    const [exp,setExp] = useState()
    const [loading,setLoading] = useState()
    const {amount,name,email,tier,uid,isDonor} = appDonation
    return(
        <div style={{width:width,height:height,backgroundColor:"#182649", display:'flex', alignItems:'center',justifyContent:'center'}}>
            {loading && <Loading/>}
            <div style={{width:"90%",backgroundColor:'white',borderRadius:5, display:'flex', alignItems:'center',justifyContent:'center',flexDirection:'column',position:'relative'}}>
                {/* logo */}
                <div
                    style={{
                        width:width * 0.2,
                        height:width * 0.2,
                        position:'absolute',
                        left: (width * 0.9 / 2) - (width * 0.1),
                        top: width * -0.1,
                        backgroundColor:'white',
                        borderRadius:width * 0.1,
                        border:'1px solid grey',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        overflow:'hidden'
                    }}                
                >
                    <img
                        src={SATLogo}
                        style={{
                            width:width * 0.2,
                            marginTop:0,
                            marginLeft:2,
                            marginBottom:1
                            // width:width * 0.15,
                            // marginTop:6
                        }}
                    />
                </div>
                <div style={{display:'flex', alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <div style={{fontWeight:'bold',marginTop:width*0.15}}>
                        {/* Support A Troop Donation Portal */}
                        iSupport Deployed Troops
                    </div>
                    <div style={{fontWeight:'bold',marginBottom:10}}>
                        {/* Support A Troop Donation Portal */}
                        Donation Checkout
                    </div>
                    <div style={{height:width*0.06,marginBottom:20}}>
                        <img
                            src={stripeBadge}
                            style={{width:width * 0.3,}}
                        />
                    </div>
                </div>
                <div style={{width:'100%',height:35,backgroundColor:'lightGrey',display:'flex', alignItems:'center',justifyContent:'center',flexDirection:'column',color:'black',fontSize:'12px'}}>
                    {email}
                </div>
                {/* widget */}
                <div style={{display:'flex', alignItems:'center',justifyContent:'center',flexDirection:'column',
                    backgroundColor:'#f2f7ff',width:'100%',height:'100%'}}
                >
                    <div style={{margin:15,fontSize:12,textAlign:'center'}}>
                        {`Thank you ${name} for your ${tier} medal monthly donation of $${amount}.`}
                    </div>
                    <div style={{width:'90%',display:'flex',alignItems:'center',flexDirection:'column'}}>
                        <div 
                            style={{
                                width:'100%',display:'flex',alignItems:'center',flexDirection:'row',
                                border:"1px solid lightGrey",boxSizing:'border-box',
                                backgroundColor:'white',
                            }}
                        >
                            <FontAwesomeIcon 
                                icon={faCreditCard}
                                color="gray"
                                style={{margin:10}}
                            />

                            <input 
                                type="text"
                                pattern="\d*"
                                placeholder="Card Number"
                                style={{width:'100%',...inputStyle,border:"none",outline:'none'}}
                                value={cardNumber}
                                onChange={e=>setCardNumber(nums(e.target.value.replace(/ /g, '').replace(/(.{4})/g, '$1 ').trim().substring(0,19)))}
                            />
                        </div>
                        <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                            <div 
                                style={{
                                    flex:1,display:'flex',alignItems:'center',
                                    flexDirection:'row',
                                    borderLeft:"1px solid lightGrey",
                                    borderBottom:"1px solid lightGrey",
                                    backgroundColor:'white'
                                }}
                            >
                                <FontAwesomeIcon 
                                    icon={faCalendar}
                                    color="gray"
                                    style={{margin:10}}
                                />
                                <input 
                                    type="text"
                                    pattern="\d*"
                                    placeholder="MM / YY"
                                    style={{
                                        width:'100%',
                                        ...inputStyle,
                                        border:"none",outline:'none'
                                        // borderLeft:'1px solid lightGrey',
                                        // borderBottom:'1px solid lightGrey',
                                    }}
                                    value={exp}
                                    onChange={e=>setExp(nums(e.target.value).replace(/ /g, '').replace(/\//g, '').replace(/(.{2})/g, '$1 / ').substring(0,7))}
                                    onKeyDown={e=>{
                                        if(e.keyCode===8){
                                            setExp('')
                                        }
                                    }}
                                />
                            </div>
                                <div 
                                    style={{
                                        flex:1,display:'flex',alignItems:'center',
                                        flexDirection:'row',
                                        borderLeft:"1px solid lightGrey",
                                        borderRight:"1px solid lightGrey",
                                        borderBottom:"1px solid lightGrey",    
                                        backgroundColor:'white'
                                    }}
                                >
                                    <FontAwesomeIcon 
                                        icon={faLock}
                                        color="gray"
                                        style={{margin:10}}
                                    />
                                <input 
                                    type="text"
                                    pattern="\d*"
                                    placeholder="CVC"
                                    style={{
                                        width:'100%',
                                        ...inputStyle,
                                        border:"none",outline:'none'                                    }}
                                    value={cvc}
                                    onChange={e=>setCvc(nums(e.target.value.trim().substring(0,3)))}
                                />
                            </div>
                        </div>
                        <div 
                            style={{width:'100%',height:35,marginTop:15,
                                backgroundColor:lightBlue,borderRadius:3,...center,cursor:'pointer'}}
                            onClick={()=>onCheckout({cardNumber,cvc,exp,amount,email,uid,tier,name,isDonor,setLoading,setShowThanks,sv})}
                        >
                            <div style={{color:'white'}}>
                                {`Donate $${amount}`}
                            </div>
                        </div>
                    </div>
                    <div style={{width:'100%',borderBottom:'1px solid lightGrey',marginTop:25}}/>
                    <div style={{width:'100%',height:30, display:'flex',flexDirection:'row'}}>
                        <div 
                            style={{ fontSize:11,flex:1,display:'flex',alignItems:'center',justifyContent:'center',color:'grey',cursor:'pointer'}}
                            onClick={()=>window.open("https://stripe.com/privacy", "_blank")}
                            >
                            privacy
                        </div>
                        <div 
                            style={{borderLeft:'1px solid lightGrey',fontSize:11,flex:1,display:'flex',alignItems:'center',justifyContent:'center',color:'grey',cursor:'pointer'}}
                            onClick={()=>window.open("https://stripe.com/checkout/legal", "_blank")}    
                        >
                            legal
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Loading = ()=>{
    const [anim,setAnim] = useState(true)
    useEffect(()=>{
        let i = setInterval(()=>setAnim(!anim),800)
        return(()=>clearInterval(i))
    })
    return(
        <div style={{
            width:'100%',
            height:'100%',
            position:'absolute',
            backgroundColor:'white',
            ...center,
            userSelect:'none',
            opacity:0.9,
            zIndex:2,
            display:'flex',
            flexDirection:'column'
        }}>
            <div style={{
                transition:'opacity 0.7s',
                opacity: anim ? 1 : 0.3,
                textAlign:'center',
                fontWeight:'bold',
                ...center,
            }}>
                Please Wait
            </div>
            <div style={{
                transition:'opacity 0.7s',
                opacity: anim ? 1 : 0.3,
                textAlign:'center',
                fontWeight:'bold',
                ...center,
            }}>
                Your Request is Processing
            </div>
        </div>
    )
}

const onCheckout = async ({cardNumber,cvc,exp,name,amount,email,uid,tier,isDonor,setLoading,setShowThanks,sv}) => {
    setLoading(true)
    let token = await getStripeToken({
        number:cardNumber,
        exp,
        cvc,
        test:false
    })
    if(token.error){
        setLoading(false)
        alert(token.error.message)
        return
    }
    let res = await onTokenAppDonation({
            token:token.id,
            amount:amount*100,
            email,
            userID:uid,
            tier,
            isDonor
        })
    if(res.err){
        console.log(res.err)
        setLoading(false) 
        alert(res.err.message)
    }else if(res.status==="SUCCESS"){
        setLoading(false) 
        // alert(`Thank you for your ${monthly ? 'monthly dontion' : 'donation'} of $${amount ? amount.toFixed(2) : parseFloat(customAmount).toFixed(2)}!`)
        setShowThanks({show:true,firstName:name,lastName:"",email,amount:parseFloat(amount),monthly:true})
        sv('home')
    } else {
        setLoading(false)
        alert('Something went wrong! Please try again!')
        console.log(res)
    }
}

export default AppDonationPortal